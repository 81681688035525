import React from "react";

export const InputField = (props) => {
  // let { FieldName, InputID, InputType, MessageDesc } = props;

  return (
    <div className="form-group">
      <label hmtlfor={props.InputID}>{props.FieldName}</label>
      <input
        type={props.InputType}
        id={props.InputID}
        className="form-control"
        name={props.InputID}
        value={props.value}
        onChange={props.onChange}
      />
      <small>{props.MessageDesc}</small>
    </div>
  );
};
