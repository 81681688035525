import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./scss/theme.scss";

import Header from "./components/app/layout/Header";
import Footer from "./components/app/layout/Footer";

import Account from "./components/app/modules/patients/Account";
import Authentication from "./components/app/modules/patients/auth/Authentication";
import ForgotPassword from "./components/app/modules/patients/auth/ForgotPassword";
import Registration from "./components/app/modules/patients/auth/Registration";
import ProtectedRoute from "./components/ProtectedRoute";
import CriticalResponse from "./components/app/modules/CriticalResponse";
import NonPatient from "./components/app/modules/NonPatient";
import Patient from "./components/app/modules/Patient";
import HigherLevelOfCare from "./components/app/modules/HigherLevelOfCare";
import HigherLevelCare from "./components/app/modules/HigherLevelCare";
import CareProvided from "./components/app/modules/CareProvided";
import ResetPassword from "./components/app/modules/patients/auth/ResetPassword";
import VerifyToken from "./components/app/modules/patients/auth/VerifyToken";
import Welcome from "./components/app/modules/Welcome";
import AdminAccount from "./components/app/modules/Admin";
function App() {
  return (
    <BrowserRouter>
      <Header />
      <Switch>
        <Route exact path="/login" component={Authentication} />
        <Route exact path="/" component={Authentication} />
        <Route exact path="/reset-password" component={ResetPassword} />
        <Route exact path="/verify" component={VerifyToken} />
        <ProtectedRoute exact path="/account" component={Account} />
        <ProtectedRoute exact path="/admin" component={AdminAccount} />
        <Route exact path="/patient/:id" component={Patient} />
        <Route exact path="/non-patient" component={NonPatient} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route exact path="/sign-up" component={Registration} />
        <Route exact path="/critical-help" component={CriticalResponse} />
        <Route exact path="/higher-care" component={CareProvided} />
        <Route exact path="/welcome" component={Welcome} />
        <Route
          exact
          path="/higher-level-of-care"
          component={HigherLevelOfCare}
        />
        <Route exact path="/higher-level-care" component={HigherLevelCare} />
      </Switch>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
