import React from "react";

export const SelectField = (props) => {
  return (
    <div className="form-group">
      <label htmlfor={props.InputID}>{props.FieldName}</label>

      <select
        id={props.InputID}
        className="form-control"
        value={props.value}
        onChange={props.onChange}
        name={props.InputID}
      >
        <option>Choose answer...</option>
        {props.Options.map((option, index) => (
          <option value={option.value} key={index}>
            {option.name}
          </option>
        ))}
      </select>
      <small>{props.MessageDesc}</small>
    </div>
  );
};
