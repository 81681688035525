/**
 * COPYRIGHT OWNER:  EWAEN OKAO, MD.
 * CONTACT: EWAEN OKAO, MD. | ewaen.okao@healthisys.com|+18622151696.
 * FILE: FRONTEND PATIENT ACCOUNT DASHBOARD
 * DEVELOPER: Victor Olaitan, Reglobe Innovation Worx. | victor@thevo.dev|+2349020686228
 * LAST UPDATED: March 29, 2022
 */

import React, { Fragment, useEffect, useState } from "react";
import Profile from "./tabs/Profile";
import Resources from "./tabs/Resources";
import { getUser, logOut } from "../../../utils/auth";
import { Link, useHistory } from "react-router-dom";

const Account = (props) => {
  const [User, setUserData] = useState("");
  const currentUser = localStorage.getItem("currentUser");
  useEffect(() => {
    getUser(currentUser).then((user) => setUserData(user));
  }, [currentUser]);

  const navigate = useHistory();

  const handleLogout = (e) => {
    e.preventDefault();
    logOut(navigate);
  };
  if (User) {
    return (
      <Fragment>
        <section className="pb-5">
          <div className="container">
            <div className="row mb-5">
              <div className="col d-flex align-items-center">
                <div className="ml-4">
                  <h1 className="mb-2">Hello {User.data.Firstname},</h1>
                  <div className="lead">
                    {" "}
                    Welcome back, hope you're doing great today!
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-5">
              {currentUser ? (
                <Link
                  onClick={(e) => handleLogout(e)}
                  rel="noreferrer"
                  className="btn btn-primary ml-lg-4 mr-3 mr-md-4 mr-lg-0 d-none d-sm-block order-lg-3"
                >
                  Sign Out
                </Link>
              ) : (
                ``
              )}
            </div>
          </div>
        </section>

        <div className="bg-white">
          <div className="container">
            <div className="row">
              <div className="col">
                <ul className="nav nav-tabs border-0" id="myTab" role="tablist">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="resources-tab"
                      data-toggle="tab"
                      href="#resources"
                      role="tab"
                      aria-controls="resources"
                      aria-selected="true"
                    >
                      Patient Onboarding{" "}
                    </a>
                  </li>

                  <li className="nav-item">
                    <a
                      className="nav-link "
                      id="profile-tab"
                      data-toggle="tab"
                      href="#profile"
                      role="tab"
                      aria-controls="profile"
                      aria-selected="false"
                    >
                      Profile
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <section className="bg-light tab-content pt-5">
          <div
            className="tab-pane fade show active"
            id="resources"
            role="tabpanel"
            aria-labelledby="resources-tab"
          >
            <Resources />
          </div>
          <div
            className="tab-pane fade "
            id="profile"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            <Profile UserProfile={User.data} />
          </div>
        </section>
      </Fragment>
    );
  } else {
    return "Loading";
  }
};
export default Account;
