import React, { Fragment } from "react";

import { SITEURL } from "../../utils/Constants";
const Header = () => {
  return (
    <Fragment>
      <div className="navbar-container">
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container">
            <a
              className="navbar-brand navbar-brand-dynamic-color fade-page flex-grow-1"
              href={SITEURL}
              target={"_blank"}
              rel="noreferrer"
            >
              <img
                alt="BMB Consultants, P.C - Brains, Minds & Behaviors"
                src="/assets/img/logos/bmbpc_logo.png"
                width="265px"
              />
            </a>
            <div className="d-flex align-items-right order-lg-3 flex-grow-1 justify-content-end">
              <a
                href={SITEURL}
                target={"_blank"}
                rel="noreferrer"
                className="btn btn-primary ml-lg-4 mr-3 mr-md-4 mr-lg-0 d-none d-sm-block order-lg-3"
              >
                Back Main Website
              </a>
            </div>
            <div className="d-flex align-items-right order-lg-3 flex-grow-1 justify-content-end">
              <a
                href={SITEURL + "/contact"}
                target={"_blank"}
                rel="noreferrer"
                className="btn btn-primary ml-lg-4 mr-3 mr-md-4 mr-lg-0 d-none d-sm-block order-lg-3"
              >
                Contact Us
              </a>
              {/*
              {isAuthenticated ? (
                <Link
                  onClick={(e) => handleLogout(e)}
                  rel="noreferrer"
                  className="btn btn-primary ml-lg-4 mr-3 mr-md-4 mr-lg-0 d-none d-sm-block order-lg-3"
                >
                  Sign Out
                </Link>
              ) : (
                <Link
                  to="/"
                  rel="noreferrer"
                  className="btn btn-primary ml-lg-4 mr-3 mr-md-4 mr-lg-0 d-none d-sm-block order-lg-3"
                >
                  Sign In
                </Link>
              )} */}

              <button
                aria-expanded="false"
                aria-label="Toggle navigation"
                className="navbar-toggler"
                data-target=".navbar-collapse"
                data-toggle="collapse"
                type="button"
              >
                <img
                  alt="Navbar Toggler Open Icon"
                  className="navbar-toggler-open icon icon-sm"
                  data-inject-svg
                  src="assets/img/icons/interface/icon-menu.svg"
                />
                <img
                  alt="Navbar Toggler Close Icon"
                  className="navbar-toggler-close icon icon-sm"
                  data-inject-svg
                  src="assets/img/icons/interface/icon-x.svg"
                />
              </button>
            </div>
            {/* {isAuthenticated ? (
              <div className="collapse navbar-collapse order-3 order-lg-2 justify-content-lg-right">
                <ul className="navbar-nav my-3 my-lg-0">
                  <li className="nav-item">
                    <Link to="/about" className="nav-link">
                      Schedule
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/services" className="nav-link">
                      Services
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/new-patients" className="nav-link">
                      New Patients
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/resources" className="nav-link">
                      Profile
                    </Link>
                  </li>
                </ul>
              </div>
            ) : (
              ""
            )} */}
          </div>
        </nav>
      </div>
    </Fragment>
  );
};

export default Header;
