import { ResourcesList } from "../../../assets/Resource";

const Resources = () => {
  return (
    <>
      <div className="container">
        <div className="row mb-4 mb-md-5 align-items-start">
          <div className="col-lg-4 order-lg-2 mb-4 mb-lg-0 sticky-lg-top pl-lg-5">
            <div className="alert alert-primary">
              Your information is always kept encrypted and can never be
              accessed by third parties.
              {/* <a href="#">View our Privacy Policy</a> */}
            </div>
            <div className="alert alert-primary">
              <ul>
                <li>
                  New Patients documents are to be downloaded, printed, reviewed
                  and completed and signed.
                </li>
                <li>
                  Should you have any difficulty downloading and printing these
                  documents and forms, a welcome package containing them is
                  available for pick up at our office address at 2626 Tilton
                  Road, Egg Harbor Township, NJ 08234 from Mondays to Fridays
                  between 4:30pm to 6:30pm.
                </li>
                <li>
                  Please complete ALL the forms identified as “Return to Clinic”
                  to us by Fax, registered mail or in Person to
                  <ul>
                    <li>
                      BMB Consultants P.C. <br />
                      2626 Tilton Road, Egg Harbor
                      <br />
                      Township, NJ 08234
                    </li>
                    <li>Fax: 609-798-0555</li>
                    <li className="alert">
                      Please don’t email the completed as the security and
                      privacy of your confidential information is not guaranteed
                      in email communication.
                    </li>
                  </ul>
                </li>
              </ul>
              {/* <a href="#">View our Privacy Policy</a> */}
            </div>
          </div>
          <div className="col-lg-8 border-lg-right pr-lg-5">
            <div className="row mb-lg-5">
              <div className="col">
                <h3>
                  Please find below links to documents for you to download
                  print, review and complete.
                </h3>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <ResourcesList />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Resources;
