import React, { Fragment } from "react";

const CriticalResponse = () => {
  return (
    <Fragment>
      <div className="bg-primary-3 o-hidden" data-overlay>
        <section className="text-white pb-0">
          <div className="container pb-5">
            <div className="row justify-content-center text-center">
              <div className="col-md-9 col-lg-8 col-xl-9">
                <h1 className="display-3">Critical Care Required</h1>
                <p className="lead mb-5">
                  Your current symptom indicate that you need an appropriate
                  level of care that is not currently available currently at BMB
                  Consultants.
                </p>
              </div>
            </div>
          </div>
          {/*<div
            className="position-absolute w-50 h-100 top left"
            data-jarallax-element="100 50"
          >
            <div className="blob blob-4 bg-gradient w-100 h-100 top left"></div>
          </div>
           <div className="divider divider-bottom bg-white"></div> */}
        </section>
      </div>
      <section>
        <div className="container">
          <div className="row align-items-center justify-content-around text-center text-lg-left">
            <div
              className="col-md-9 col-lg-9 col-xl-9 mb-4 mb-md-5 mb-lg-0 order-lg-2"
              data-aos="fade-in"
            >
              <h6 className="h3">Please</h6>
              <ul className="lead">
                <li className="pb-3">
                  <strong>Call Local 911 </strong>or go to the nearest Emergency
                  Room if you are safe to do so or <br />
                  Contact Designated Psychiatric Screening center in your
                  County, <strong>FOR NJ RESIDENTS</strong>
                  <ul>
                    <li>
                      <a href="https://www.state.nj.us/humanservices/dmhas/home/hotlines/MH_Screening_Centers.pdf">
                        {" "}
                        DOWNLOAD (ENGLISH)
                      </a>
                    </li>
                    <li>
                      <a href="https://www.state.nj.us/humanservices/dmhas/home/hotlines/MH_Screening_Centers_Spanish.pdf">
                        {" "}
                        DOWNLOAD (SPANISH)
                      </a>
                    </li>
                  </ul>
                </li>{" "}
                <li className="pb-3">
                  Call the New Jersey Suicide Prevention{" "}
                  <strong>HOPELINE, 855-NJHopeline, 855-654-6735 </strong>
                </li>{" "}
                <li className="pb-3">
                  Call the National Suicide Prevention Lifeline{" "}
                  <strong>1-800-272-TALK (8255) </strong>- – free and
                  confidential support for people in distress, 24/7 (From July
                  16, 2022, Just dial 988)
                </li>{" "}
                <li className="pb-3">
                  National Domestic Violence Hotline
                  <strong> 1-800-799-7233 | 1-800-787-3224 (TTY)</strong>
                </li>{" "}
              </ul>{" "}
            </div>
            {/* <div
              className="col-md-9 col-lg-6 col-xl-4 order-lg-1"
              data-aos="fade-in"
            >
              <img
                src="assets/img/about-2.jpg"
                alt="About"
                className="img-fluid rounded shadow"
              />
            </div> */}
          </div>
        </div>
      </section>
    </Fragment>
  );
};
export default CriticalResponse;
