/**
 * COPYRIGHT OWNER:  EWAEN OKAO, MD.
 * CONTACT: EWAEN OKAO, MD. | ewaen.okao@healthisys.com|+18622151696.
 * FILE: FRONTEND LOGIC
 * DEVELOPER: Victor Olaitan, Reglobe Innovation Worx. | victor@thevo.dev|+2349020686228
 * LAST UPDATED: March 29, 2022
 */

import { httpURL, privateHttpURL } from "./api";

export const logIn = async (data) => {
  const { userData, navigate } = data;
  try {
    const response = await httpURL({
      method: "post",
      url: "auth/login/",
      data: userData,
    });
    // console.log(response.data.data.User);
    if (response.data.data.User.Role === "Admin") {
      localStorage.setItem("accessToken", response.data.data.accessToken);
      localStorage.setItem("currentUser", response.data.data.User._id);
      localStorage.setItem("isAuthenticated", true);
      return navigate.push("/admin", response.data);
    } else if (
      (response.data.data.User.PatientReviewed === true &&
        response.data.data.User.PatientRejected === true) ||
      (response.data.data.User.PatientReviewed === false &&
        response.data.data.User.PatientRejected === " ")
    ) {
      return navigate.push("/non-patient");
    } else {
      localStorage.setItem("accessToken", response.data.data.accessToken);
      localStorage.setItem("currentUser", response.data.data.User._id);
      localStorage.setItem("isAuthenticated", true);
      return navigate.push("/account", response.data);
    }
  } catch (error) {
    return error.response;
  }
};

export const registerUser = async (data) => {
  const { userData, navigate } = data;

  try {
    const response = await httpURL({
      method: "post",
      url: "auth/register/",
      data: userData,
    });

    return navigate.push("/welcome", { ...response.data });
  } catch (error) {
    return error.response;
  }
};

export const verifyUser = async (data) => {
  const { userData, navigate } = data;

  try {
    const response = await httpURL({
      method: "post",
      url: "/auth/verify-token/",
      data: userData,
    });

    navigate.push("/login", response.data);
    window.location.reload();
    return response.data;
  } catch (error) {
    return error.response;
  }
};

export const forgotPassword = async (data) => {
  const { userData } = data;
  console.log(userData);
  try {
    const response = await httpURL({
      method: "post",
      url: "/auth/forgot-password/",
      data: userData,
    });
    return response;
    // navigate.push("/reset-password");
  } catch (error) {
    return error.response;
  }
};

export const resetPassword = async (data) => {
  const { userData, navigate } = data;
  try {
    const response = await httpURL({
      method: "patch",
      url: "/auth/reset-password/",
      data: userData,
    });

    navigate.push("/login", response.data);
    // window.location.reload();
  } catch (error) {
    return error.response;
  }
};

export const updateUser = async (data) => {
  const { userData } = data;
  console.log(userData);
  try {
    const response = await httpURL({
      method: "patch",
      url: "/auth/update-profile/",
      data: userData,
    });
    window.location.reload();

    return response.data;
  } catch (error) {
    return error.response;
  }
};

export const logOut = (navigate) => {
  localStorage.clear();
  window.location.reload();
  navigate.push("/");
};

export const getPatients = async () => {
  try {
    const patients = await privateHttpURL({
      method: "get",
      url: "auth/users/?Role=Patient",
    });

    return patients.data;
  } catch (error) {
    return error.response;
  }
};

export const getUser = async (id) => {
  try {
    const patient = await privateHttpURL({
      method: "get",
      url: `auth/users/fetch/?id=${id}`,
    });

    return patient.data;
  } catch (error) {
    return error.response;
  }
};

export const acceptPatient = async (id) => {
  // const { id, } = data;
  try {
    const patient = await privateHttpURL({
      method: "get",
      url: `auth/users/patient/accept/?id=${id}`,
    });

    // console.log(patient);

    return patient.data;
    // navigate.push(`/patient/${id}`, patient.data);
  } catch (error) {
    return error.response;
  }
};

export const rejectPatient = async (id) => {
  // const { id, } = data;
  try {
    const patient = await privateHttpURL({
      method: "get",
      url: `auth/users/patient/reject/?id=${id}`,
    });

    // console.log(patient);

    return patient.data;
    // navigate.push(`/patient/${id}`, patient.data);
  } catch (error) {
    return error.response;
  }
};
