/**
 * COPYRIGHT OWNER:  EWAEN OKAO, MD.
 * CONTACT: EWAEN OKAO, MD. | ewaen.okao@healthisys.com|+18622151696.
 * FILE: FRONTEND REGISTRATION
 * DEVELOPER: Victor Olaitan, Reglobe Innovation Worx. | victor@thevo.dev|+2349020686228
 * LAST UPDATED: March 29, 2022
 */

import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { InputField } from "../../../assets/InputField";
import { Question } from "../../../assets/Questionnaire";
import { TextArea } from "../../../assets/TextArea";
import {
  payer,
  options,
  referrer,
  GeneralQuestions,
  MentalTreatment,
  currentgender,
  genderatbirth,
  TreatedAilments,
} from "../../../assets/Questions";
import { SelectField } from "../../../assets/Select";
import { Redirect } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { registerUser } from "../../../../utils/auth";

const animatedComponents = makeAnimated();

const Registration = () => {
  // Perform Registration action

  const [submit, setSubmit] = useState(false);
  const [error, setError] = useState("");
  const navigate = useHistory();

  const handleChange = (e) => {
    const value = e.target.value;
    setState({
      ...state,
      [e.target.name]: value,
    });
  };

  const [state, setState] = useState({
    firstname: "",
    lastname: "",
    emailaddress: "",
    homephonenumber: "",
    mobilephonenumber: "",
    dateofbirth: "",
    currentgender: "",
    preferredtobeaddressedas: "",
    genderatbirth: "",
    password: "",
    confirmpassword: "",
    streetaddress: "",
    apartmentsuite: "",
    city: "",
    state: "",
    zipcode: "",
    county: "",
    payer: "",
    medicalinsurancecompany: "",
    subscribername: "",
    memberidentificationnumber: "",
    psychhelpreason: "",
    referredby: "",
    question1: "",
    question2: "",
    question3: "",
    question4: "",
    question5: "",
    question6: "",
    question7: "",
    question8: "",
    question9: "",
    question10: "",
    question11: "",
    question12: "",
    question13: "",
    question14: "",
    question15: "",
    question16: "",
    question17: "",
    question18: "",
    question19: "",
    question20: "",
    question21: "",
    question22: "",
    question23: "",
    question24: "",
    question25: "",
    question26: "",
    question27: "",
    question28: "",
    question29: "",
    question30: "",
    question31: "",
    question32: "",
    question33: "",
    question34: "",
    prescribeddrugs: "",
    prescriber: "",
    prescribernumber: "",
    covidvaccination: "",
    covidinfection: "",
    smartdevice: "",
    internetconnection: "",
    smartdeviceuseage: "",
    telehealthvisits: "",
    useoftech: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    let patientData = {
      dateofbirth: state.dateofbirth,
      currentgender: state.currentgender,
      preferredtobeaddressedas: state.preferredtobeaddressedas,
      genderatbirth: state.genderatbirth,
      streetaddress: state.streetaddress,
      apartmentsuite: state.apartmentsuite,
      city: state.city,
      state: state.state,
      zipcode: state.zipcode,
      county: state.county,
      referredby: state.referredby,
      payer: state.payer,
      medicalinsurancecompany: state.medicalinsurancecompany,
      subscribername: state.subscribername,
      memberidentificationnumber: state.memberidentificationnumber,
      psychhelpreason: state.psychhelpreason,
      screeningresponses: {
        question1: state.question1,
        question2: state.question2,
        question3: state.question3,
        question4: state.question4,
        question5: state.question5,
        question6: state.question6,
        question7: state.question7,
        question8: state.question8,
        question9: state.question9,
        question10: state.question10,
        question11: state.question11,
        question12: state.question12,
        question13: state.question13,
        question14: state.question14,
        question15: state.question15,
        question16: state.question16,
        question17: state.question17,
        question18: state.question18,
        question19: state.question19,
        question20: state.question20,
        question21: state.question21,
        question22: state.question22,
        question23: state.question23,
        question24: state.question24,
        question25: state.question25,
        question26: state.question26,
        question27: state.question27,
        question28: state.question28,
        question29: state.question29,
        question30: state.question30,
        question31: state.question31,
        question32: state.question32,
        question33: state.question33,
        question34: state.question34,
        prescribeddrugs: state.prescribeddrugs,
        prescriber: state.prescriber,
        prescribernumber: state.prescribernumber,
        covidvaccination: state.covidvaccination,
        covidinfection: state.covidinfection,
        smartdevice: state.smartdevice,
        internetconnection: state.internetconnection,
        smartdeviceuseage: state.smartdeviceuseage,
        telehealthvisits: state.telehealthvisits,
        useoftech: state.useoftech,
      },
    };

    let userInfo = {
      Firstname: state.firstname,
      Lastname: state.lastname,
      Email: state.emailaddress,
      Password: state.password,
      MobilePhonenumber: state.mobilephonenumber,
      HomePhonenumber: state.homephonenumber,
    };

    if (userInfo) {
      let userData = {
        ...userInfo,
        PatientData: patientData,
      };
      try {
        setSubmit(true);

        const response = await registerUser({
          userData,
          navigate,
        });
        const { message } = response.data;

        const status = response ? response.status : null;

        if (status === 403 || status === 401) {
          setSubmit(false);
          setError(message);
        }
        return response;
      } catch (error) {
        setSubmit(false);

        return error;
      }
    } else {
      window.alert("Required fields cannot be left empty ");
      setSubmit(false);
    }
  };
  return (
    <>
      <section className="pb-5">
        <div className="container">
          <div className="row mb-5">
            <div className="col d-flex align-items-center">
              <div className="ml-4">
                <h1 className="mb-2">Initial Screening Assessment</h1>
                {/* <div className="lead">
                  {" "}
                  Welcome back, hope you're doing great today!
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-light tab-content pt-5">
        <div
          className="tab-pane fade show active"
          id="general"
          role="tabpanel"
          aria-labelledby="general-tab"
        >
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="container">
              <div className="row mb-4 mb-md-5 align-items-start">
                <div className="col-lg-4 order-lg-2 mb-4 mb-lg-0 sticky-lg-top pl-lg-5">
                  {error ? (
                    <div className="alert alert-primary">{error}</div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-lg-11 border-lg-right pr-lg-5">
                  <div className="row mb-lg-5">
                    <div className="col">
                      <h3>Personal Information</h3>
                    </div>
                  </div>
                  <div className="row form-row">
                    <div className="col-sm-6">
                      <InputField
                        FieldName="First Name"
                        InputType="text"
                        InputID="firstname"
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                    <div className="col-sm-6">
                      <InputField
                        FieldName="Last Name"
                        InputType="text"
                        InputID="lastname"
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </div>
                  <div className="row form-row">
                    <div className="col-sm-6">
                      <InputField
                        FieldName="Preferred to be addressed as"
                        InputType="text"
                        InputID="preferredtobeaddressedas"
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                    <div className="col-sm-6">
                      <InputField
                        FieldName="Date of Birth"
                        InputType="date"
                        InputID="dateofbirth"
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </div>
                  <div className="row form-row">
                    <div className="col-sm-6">
                      <Question
                        Question="Gender at Birth "
                        Options={genderatbirth}
                        InputID="genderatbirth"
                        onChange={(e) => handleChange(e)}
                        value={state.genderatbirth}
                      />
                    </div>
                    <div className="col-sm-6">
                      <Question
                        Question="Current Identified Gender "
                        Options={currentgender}
                        InputID="currentgender"
                        onChange={(e) => handleChange(e)}
                        value={state.currentgender}
                      />
                    </div>
                  </div>
                  <div className="row form-row">
                    <div className="col-sm-12">
                      <InputField
                        FieldName="Email Address"
                        InputType="email"
                        InputID="emailaddress"
                        MessageDesc="Used to log in to your account"
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </div>
                  <div className="row form-row">
                    <div className="col-sm-6">
                      <InputField
                        FieldName="Home Phone Number"
                        InputType="number"
                        InputID="homephonenumber"
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                    <div className="col-sm-6">
                      <InputField
                        FieldName="Mobile Phone Number"
                        InputType="number"
                        InputID="mobilephonenumber"
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </div>
                  <div className="row form-row">
                    <div className="col-sm-6">
                      <InputField
                        FieldName="Password"
                        InputType="password"
                        InputID="password"
                        MessageDesc="Used to log in to your account"
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                    <div className="col-sm-6">
                      <InputField
                        FieldName="Confirm Password"
                        InputType="password"
                        InputID="confirmpassword"
                        onChange={(e) => handleChange(e)}
                      />
                      {state.password &&
                      state.confirmpassword &&
                      state.password !== state.confirmpassword ? (
                        <div className="alert alert-primary">
                          Your passwords do not match
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="row form-row">
                    <div className="col-sm-12">
                      <InputField
                        FieldName="Street Address"
                        InputType="text"
                        InputID="streetaddress"
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                    <div className="col-sm-6">
                      <InputField
                        FieldName="Apartment Suite"
                        InputType="text"
                        InputID="apartmentsuite"
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                    <div className="col-sm-6">
                      <InputField
                        FieldName="City"
                        InputType="text"
                        InputID="city"
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </div>
                  <div className="row form-row">
                    <div className="col-sm-4">
                      <InputField
                        FieldName="County"
                        InputType="text"
                        InputID="county"
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                    <div className="col-sm-4">
                      <InputField
                        FieldName="State"
                        InputType="text"
                        InputID="state"
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                    <div className="col-sm-4">
                      <InputField
                        FieldName="Zip Code"
                        InputType="text"
                        InputID="zipcode"
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </div>
                  <Question
                    Question="Referred From"
                    Options={referrer}
                    message="How are you referred to us?"
                    InputID="referredby"
                    onChange={(e) => handleChange(e)}
                    value={state.referredby}
                  />
                  <Question
                    Question="Payer"
                    Options={payer}
                    message="Who will be payming for the cost of your treatment?"
                    InputID="payer"
                    onChange={(e) => handleChange(e)}
                    value={state.payer}
                  />
                  {(state.payer && state.payer === "PrivateInsurance") ||
                  (state.payer && state.payer === "UnionInsurance") ||
                  (state.payer && state.payer === "MedicAid") ||
                  (state.payer && state.payer === "MedicCare") ? (
                    <div className="row form-row">
                      <div className="col-sm-12">
                        <InputField
                          InputType="text"
                          FieldName="Subscriber First and Last Name (If you are not the subscriber)"
                          InputID="subscribername"
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                      <div className="col-sm-6">
                        <InputField
                          FieldName="Name of Medical Insurance Company"
                          InputType="text"
                          InputID="medicalinsurancecompany"
                          onChange={(e) => handleChange(e)}
                        />
                      </div>

                      <div className="col-sm-6">
                        <InputField
                          FieldName="Member Identification Number"
                          InputType="text"
                          InputID="memberidentificationnumber"
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="row form-row">
                    <div className="col-sm-12">
                      <TextArea
                        FieldName="Reason for seeking Psychiatrist"
                        InputID="psychhelpreason"
                        rows="2"
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <hr className="mb-4 mb-md-5" />
              <div className="row mb-4 mb-md-5 align-items-start">
                <div className="col-lg-1 order-lg-1 mb-4 mb-lg-0 sticky-lg-top pl-lg-5">
                  {(state.question1 && state.question1 === "yes") ||
                  (state.question3 && state.question3 === "yes") ||
                  (state.question7 && state.question7 === "yes") ||
                  (state.question10 && state.question10 === "yes") ||
                  (state.question12 && state.question12 === "yes") ||
                  (state.question16 && state.question16 === "yes") ? (
                    <Redirect to="/critical-help" />
                  ) : (
                    ``
                  )}
                  {(state.question13 && state.question13 === "yes") ||
                  (state.question25 && state.question25 === "yes") ||
                  (state.question26 && state.question26 === "yes") ||
                  (state.question27 && state.question27 === "yes") ||
                  (state.question16 && state.question16 === "yes") ? (
                    <Redirect to="/higher-care" />
                  ) : (
                    ``
                  )}
                  {(state.question14 && state.question14 === "yes") ||
                  (state.question16 && state.question16 === "yes") ||
                  (state.question17 && state.question17 === "yes") ||
                  (state.question15 && state.question15 === "yes") ? (
                    <Redirect to="/higher-level-of-care" />
                  ) : (
                    ``
                  )}
                </div>
                <div className="col-lg-11 border-lg-right pr-lg-5">
                  <div className="row mb-lg-5">
                    <div className="col">
                      <h3>Current State of Mind</h3>
                    </div>
                  </div>

                  <SelectField
                    FieldName={GeneralQuestions[0].title}
                    Options={options}
                    message={GeneralQuestions[0].message}
                    key={GeneralQuestions[0].id}
                    InputID={GeneralQuestions[0].id}
                    onChange={(e) => handleChange(e)}
                    value={state.question1}
                  />
                  <SelectField
                    FieldName={GeneralQuestions[1].title}
                    Options={options}
                    message={GeneralQuestions[1].message}
                    key={GeneralQuestions[1].id}
                    InputID={GeneralQuestions[1].id}
                    onChange={(e) => handleChange(e)}
                    value={state.question2}
                  />
                  <SelectField
                    FieldName={GeneralQuestions[2].title}
                    Options={options}
                    message={GeneralQuestions[2].message}
                    key={GeneralQuestions[2].id}
                    InputID={GeneralQuestions[2].id}
                    onChange={(e) => handleChange(e)}
                    value={state.question3}
                  />
                  <SelectField
                    FieldName={GeneralQuestions[3].title}
                    Options={options}
                    message={GeneralQuestions[3].message}
                    key={GeneralQuestions[3].id}
                    InputID={GeneralQuestions[3].id}
                    onChange={(e) => handleChange(e)}
                    value={state.question4}
                  />
                  <SelectField
                    FieldName={GeneralQuestions[4].title}
                    Options={options}
                    message={GeneralQuestions[4].message}
                    key={GeneralQuestions[4].id}
                    InputID={GeneralQuestions[4].id}
                    onChange={(e) => handleChange(e)}
                    value={state.question5}
                  />
                  {state.question5 && state.question5 === "yes" ? (
                    <SelectField
                      FieldName={GeneralQuestions[5].title}
                      Options={options}
                      message={GeneralQuestions[5].message}
                      key={GeneralQuestions[5].id}
                      InputID={GeneralQuestions[5].id}
                      onChange={(e) => handleChange(e)}
                      value={state.question6}
                    />
                  ) : (
                    " "
                  )}

                  {state.question6 === "yes" &&
                  state.question6 !== "Choose answer..." &&
                  state.question5 === "yes" &&
                  state.question5 !== "Choose answer..." ? (
                    <SelectField
                      FieldName={GeneralQuestions[6].title}
                      Options={options}
                      message={GeneralQuestions[6].message}
                      key={GeneralQuestions[6].id}
                      InputID={GeneralQuestions[6].id}
                      onChange={(e) => handleChange(e)}
                      value={state.question7}
                    />
                  ) : (
                    " "
                  )}
                  <SelectField
                    FieldName={GeneralQuestions[7].title}
                    Options={options}
                    message={GeneralQuestions[7].message}
                    key={GeneralQuestions[7].id}
                    InputID={GeneralQuestions[7].id}
                    onChange={(e) => handleChange(e)}
                    value={state.question8}
                  />
                  {state.question8 && state.question8 === "yes" ? (
                    <SelectField
                      FieldName={GeneralQuestions[8].title}
                      Options={options}
                      message={GeneralQuestions[8].message}
                      key={GeneralQuestions[8].id}
                      InputID={GeneralQuestions[8].id}
                      onChange={(e) => handleChange(e)}
                      value={state.question9}
                    />
                  ) : (
                    " "
                  )}

                  {state.question8 === "yes" &&
                  state.question8 !== "Choose answer..." &&
                  state.question9 === "yes" &&
                  state.question9 !== "Choose answer..." ? (
                    <SelectField
                      FieldName={GeneralQuestions[9].title}
                      Options={options}
                      message={GeneralQuestions[9].message}
                      key={GeneralQuestions[9].id}
                      InputID={GeneralQuestions[9].id}
                      onChange={(e) => handleChange(e)}
                      value={state.question10}
                    />
                  ) : (
                    " "
                  )}
                  <SelectField
                    FieldName={GeneralQuestions[10].title}
                    Options={options}
                    message={GeneralQuestions[10].message}
                    key={GeneralQuestions[10].id}
                    InputID={GeneralQuestions[10].id}
                    onChange={(e) => handleChange(e)}
                    value={state.question11}
                  />

                  {state.question11 === "yes" &&
                  state.question11 !== "Choose answer..." ? (
                    <SelectField
                      FieldName={GeneralQuestions[11].title}
                      Options={options}
                      message={GeneralQuestions[11].message}
                      key={GeneralQuestions[11].id}
                      InputID={GeneralQuestions[11].id}
                      onChange={(e) => handleChange(e)}
                      value={state.question12}
                    />
                  ) : (
                    " "
                  )}

                  <SelectField
                    FieldName={GeneralQuestions[12].title}
                    Options={options}
                    message={GeneralQuestions[12].message}
                    key={GeneralQuestions[12].id}
                    InputID={GeneralQuestions[12].id}
                    onChange={(e) => handleChange(e)}
                    value={state.question13}
                  />

                  <hr className="mb-4 mb-md-5" />
                  <div className="row mb-lg-5">
                    <div className="col">
                      <h3>Alcohol, Substance or Tobacco Use</h3>
                    </div>
                  </div>
                  <SelectField
                    FieldName={GeneralQuestions[13].title}
                    Options={options}
                    message={GeneralQuestions[13].message}
                    key={GeneralQuestions[13].id}
                    InputID={GeneralQuestions[13].id}
                    onChange={(e) => handleChange(e)}
                    value={state.question14}
                  />

                  <SelectField
                    FieldName={GeneralQuestions[14].title}
                    Options={options}
                    MessageDesc={GeneralQuestions[14].message}
                    key={GeneralQuestions[14].id}
                    InputID={GeneralQuestions[14].id}
                    onChange={(e) => handleChange(e)}
                    value={state.question15}
                  />
                  <SelectField
                    FieldName={GeneralQuestions[15].title}
                    Options={options}
                    MessageDesc={GeneralQuestions[15].message}
                    key={GeneralQuestions[15].id}
                    InputID={GeneralQuestions[15].id}
                    onChange={(e) => handleChange(e)}
                    value={state.question16}
                  />
                  <SelectField
                    FieldName={GeneralQuestions[16].title}
                    Options={options}
                    MessageDesc={GeneralQuestions[16].message}
                    key={GeneralQuestions[16].id}
                    InputID={GeneralQuestions[16].id}
                    onChange={(e) => handleChange(e)}
                    value={state.question17}
                  />
                  <SelectField
                    FieldName={GeneralQuestions[17].title}
                    Options={options}
                    MessageDesc={GeneralQuestions[17].message}
                    key={GeneralQuestions[17].id}
                    InputID={GeneralQuestions[17].id}
                    onChange={(e) => handleChange(e)}
                    value={state.question18}
                  />

                  <SelectField
                    FieldName={GeneralQuestions[19].title}
                    Options={options}
                    MessageDesc={GeneralQuestions[19].message}
                    key={GeneralQuestions[19].id}
                    InputID={GeneralQuestions[19].id}
                    onChange={(e) => handleChange(e)}
                    value={state.question20}
                  />
                  <SelectField
                    FieldName={GeneralQuestions[20].title}
                    Options={options}
                    MessageDesc={GeneralQuestions[20].message}
                    key={GeneralQuestions[20].id}
                    InputID={GeneralQuestions[20].id}
                    onChange={(e) => handleChange(e)}
                    value={state.question21}
                  />

                  <SelectField
                    FieldName={GeneralQuestions[21].title}
                    Options={options}
                    MessageDesc={GeneralQuestions[21].message}
                    key={GeneralQuestions[21].id}
                    InputID={GeneralQuestions[21].id}
                    onChange={(e) => handleChange(e)}
                    value={state.question22}
                  />
                  <SelectField
                    FieldName={GeneralQuestions[22].title}
                    Options={options}
                    MessageDesc={GeneralQuestions[22].message}
                    key={GeneralQuestions[22].id}
                    InputID={GeneralQuestions[22].id}
                    onChange={(e) => handleChange(e)}
                    value={state.question23}
                  />
                  <hr className="mb-4 mb-md-5" />
                  <div className="row mb-lg-5">
                    <div className="col">
                      <h3>Physical Health Issues</h3>
                    </div>
                  </div>
                  <SelectField
                    FieldName={GeneralQuestions[23].title}
                    Options={options}
                    MessageDesc={GeneralQuestions[23].message}
                    key={GeneralQuestions[23].id}
                    InputID={GeneralQuestions[23].id}
                    onChange={(e) => handleChange(e)}
                    value={state.question24}
                  />

                  <SelectField
                    FieldName={GeneralQuestions[24].title}
                    Options={options}
                    MessageDesc={GeneralQuestions[24].message}
                    key={GeneralQuestions[24].id}
                    InputID={GeneralQuestions[24].id}
                    onChange={(e) => handleChange(e)}
                    value={state.question25}
                  />
                  <SelectField
                    FieldName={GeneralQuestions[25].title}
                    Options={options}
                    MessageDesc={GeneralQuestions[25].message}
                    key={GeneralQuestions[25].id}
                    InputID={GeneralQuestions[25].id}
                    onChange={(e) => handleChange(e)}
                    value={state.question26}
                  />
                  <SelectField
                    FieldName={GeneralQuestions[26].title}
                    Options={options}
                    MessageDesc={GeneralQuestions[26].message}
                    key={GeneralQuestions[26].id}
                    InputID={GeneralQuestions[26].id}
                    onChange={(e) => handleChange(e)}
                    value={state.question27}
                  />

                  {/* <SelectField
                    FieldName={GeneralQuestions[27].title}
                    Options={options}
                    MessageDesc={GeneralQuestions[27].message}
                    key={GeneralQuestions[27].id}
                    InputID={GeneralQuestions[27].id}
                    onChange={(e) => handleChange(e)}
                    value={state.question28}
                  /> */}
                  <div className="row form-row">
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label hmtlFor="TreatedAilments">
                          Have you or are you currently being treated for any of
                          the following physical health problems? Select all
                          that applies
                        </label>

                        <Select
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          isMulti
                          options={TreatedAilments}
                        />
                      </div>
                    </div>
                  </div>
                  <hr className="mb-4 mb-md-5" />
                  <div className="row mb-lg-5">
                    <div className="col">
                      <h3>Treatment History</h3>
                    </div>
                  </div>
                  <SelectField
                    FieldName={GeneralQuestions[28].title}
                    Options={options}
                    MessageDesc={GeneralQuestions[28].message}
                    key={GeneralQuestions[28].id}
                    InputID={GeneralQuestions[28].id}
                    onChange={(e) => handleChange(e)}
                    value={state.question29}
                  />

                  <SelectField
                    FieldName={GeneralQuestions[29].title}
                    Options={options}
                    MessageDesc={GeneralQuestions[29].message}
                    key={GeneralQuestions[29].id}
                    InputID={GeneralQuestions[29].id}
                    onChange={(e) => handleChange(e)}
                    value={state.question30}
                  />
                  <SelectField
                    FieldName={GeneralQuestions[30].title}
                    Options={options}
                    MessageDesc={GeneralQuestions[30].message}
                    key={GeneralQuestions[30].id}
                    InputID={GeneralQuestions[30].id}
                    onChange={(e) => handleChange(e)}
                    value={state.question31}
                  />
                  <SelectField
                    FieldName={GeneralQuestions[31].title}
                    Options={options}
                    MessageDesc={GeneralQuestions[31].message}
                    key={GeneralQuestions[31].id}
                    InputID={GeneralQuestions[31].id}
                    onChange={(e) => handleChange(e)}
                    value={state.question32}
                  />
                  <SelectField
                    FieldName={GeneralQuestions[32].title}
                    Options={options}
                    MessageDesc={GeneralQuestions[32].message}
                    key={GeneralQuestions[32].id}
                    InputID={GeneralQuestions[32].id}
                    onChange={(e) => handleChange(e)}
                    value={state.question33}
                  />
                  {(state.question33 && state.question33 === "yes") ||
                  (state.question33 &&
                    state.question33 === "Choose answer...") ? (
                    <div>
                      <div className="row form-row">
                        <div className="col-sm-12">
                          <InputField
                            FieldName="Names of Medication"
                            InputType="text"
                            InputID="prescribeddrugs"
                            onChange={(e) => handleChange(e)}
                          />
                        </div>
                      </div>
                      <div className="row form-row">
                        <div className="col-sm-6">
                          <InputField
                            FieldName="Name of Prescriber"
                            InputType="text"
                            InputID="prescriber"
                            onChange={(e) => handleChange(e)}
                          />
                        </div>
                        <div className="col-sm-6">
                          <InputField
                            FieldName="Prescriber Phone Number"
                            InputType="text"
                            InputID="prescribernumber"
                            onChange={(e) => handleChange(e)}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="row form-row">
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label hmtlfor="MentalTreatment">
                          Have you had prior mental health treatment in any
                          form? Select all that applies
                        </label>

                        <Select
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          isMulti
                          options={MentalTreatment}
                        />
                      </div>
                    </div>
                  </div>
                  <hr className="mb-4 mb-md-5" />
                  <div className="row mb-lg-5">
                    <div className="col">
                      <h3>COVID-19 Status </h3>
                    </div>
                  </div>
                  <Question
                    Question="Have you been fully vaccinated against COVID-19?"
                    Options={options}
                    InputID="covidvaccination"
                    onChange={(e) => handleChange(e)}
                  />
                  <Question
                    Question="Have you had and/or recovered from COVID-19 infection?"
                    Options={options}
                    InputID="covidinfection"
                    onChange={(e) => handleChange(e)}
                  />
                  <hr className="mb-4 mb-md-5" />
                  <div className="row mb-lg-5">
                    <div className="col">
                      <h3>Tele-Health Screen</h3>
                    </div>
                  </div>
                  <Question
                    Question="Do you have smart phone or computer/laptop with webcam?"
                    Options={options}
                    InputID="smartdevice"
                    onChange={(e) => handleChange(e)}
                  />
                  <Question
                    Question="Do you have a good internet connection at where you will be for your appointments?"
                    Options={options}
                    InputID="internetconnection"
                    onChange={(e) => handleChange(e)}
                  />
                  <Question
                    Question="Are you confident in using your Smartphone or computer/laptop to engage in a doctor’s appointment?"
                    Options={options}
                    InputID="smartdeviceuseage"
                    onChange={(e) => handleChange(e)}
                  />
                  <Question
                    Question="Do you agree to engage in Telehealth Visits?"
                    Options={options}
                    InputID="telehealthvisits"
                    onChange={(e) => handleChange(e)}
                  />
                  <Question
                    Question="Are you interested in the using technology such as apps on a smart phone and or internet based portals to proactively manage your symptoms, follow your progress towards your treatment goals? "
                    Options={options}
                    InputID="useoftech"
                    onChange={(e) => handleChange(e)}
                  />
                  {submit ? (
                    <button
                      className="btn btn-primary btn-block"
                      disabled
                      type="submit"
                    >
                      Loading
                    </button>
                  ) : (
                    <button className="btn btn-primary btn-block" type="submit">
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};
export default Registration;
