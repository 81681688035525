import React, { Fragment } from "react";
import { SITEURL } from "../../utils/Constants";
/**
 * Loads once a user completes registration
 */
const NonPatient = () => {
  return (
    <Fragment>
      {/* <div className="bg-primary-3 o-hidden" data-overlay>
        <section className="text-white pb-0">
          <div className="container pb-5">
            <div className="row justify-content-center text-center">
              <div className="col-md-9 col-lg-8 col-xl-9">
                <h1 className="display-3">
                  The Initial Screening Assessment (ISA) you completed, is
                  currently under review.
                </h1>
                <p className="lead mb-5">
                  We're sorry you're not able to login{" "}
                </p>
              </div>
            </div>
          </div>
          {/*<div
            className="position-absolute w-50 h-100 top left"
            data-jarallax-element="100 50"
          >
            <div className="blob blob-4 bg-gradient w-100 h-100 top left"></div>
          </div>
           <div className="divider divider-bottom bg-white"></div>
        </section>
      </div> */}
      <section>
        <div className="container">
          <div className="row align-items-center justify-content-around text-center text-lg-left">
            <div
              className="col-md-9 col-lg-9 col-xl-9 mb-4 mb-md-5 mb-lg-0 order-lg-2"
              data-aos="fade-in"
            >
              <h6 className="h3">
                The Initial Screening Assessment (ISA) you completed, is
                currently under review.
              </h6>
              <ul className="lead">
                <li>
                  Please check your email within the next 48 hours as we reach
                  out to all prospective clients within 48 hours after
                  completion of the assessment or the next business day if the
                  assessment was completed on a weekend day.
                </li>
                <li>
                  If you are having a psychiatric or medical emergency, please
                  CALL 911 or go directly to your nearest emergency department
                  if you feel safe to do so.{" "}
                  <a
                    href={SITEURL + "/resources"}
                    rel="noreferrer"
                    target={"_blank"}
                  >
                    Check for more help resources
                  </a>
                </li>
                <li>
                  Thank you for completing the initial screening assessment.
                </li>
              </ul>{" "}
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};
export default NonPatient;
