/**
 * COPYRIGHT OWNER:  EWAEN OKAO, MD.
 * CONTACT: EWAEN OKAO, MD. | ewaen.okao@healthisys.com|+18622151696.
 * FILE: FRONTEND ADMIN DASHBOARD
 * DEVELOPER: Victor Olaitan, Reglobe Innovation Worx. | victor@thevo.dev|+2349020686228
 * LAST UPDATED: March 29, 2022
 */

import React, { Fragment, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Profile from "./patients/tabs/Profile";
import { getPatients, getUser, logOut } from "../../utils/auth";

const AdminAccount = (props) => {
  const [PatientsData, setPatientsData] = useState([]);
  const [User, setUserData] = useState("");
  const currentUser = localStorage.getItem("currentUser");
  const navigate = useHistory();

  useEffect(() => {
    getUser(currentUser).then((user) => setUserData(user));
  }, [currentUser]);

  useEffect(() => {
    const fetchPatients = async () => {
      const patients = await getPatients();
      setPatientsData(patients);
    };
    fetchPatients();
  }, []);

  const handleLogout = (e) => {
    e.preventDefault();
    logOut(navigate);
  };

  if (User) {
    return (
      <Fragment>
        <section className="pb-5">
          <div className="container">
            <div className="row mb-5">
              <div className="col d-flex align-items-center">
                {/* <div className="d-inline-block p-3 p-md-4 rounded-circle bg-primary-alt">
                <img
                  src="assets/img/icons/theme/general/settings-1.svg"
                  alt=""
                  className="icon bg-primary"
                  data-inject-svg="data-inject-svg"
                />
              </div> */}
                <div className="ml-4">
                  <h1 className="mb-2">Hello {User.data.Firstname},</h1>
                  <div className="lead">
                    {" "}
                    Welcome back, hope you're doing great today!
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-5">
              {currentUser ? (
                <Link
                  onClick={(e) => handleLogout(e)}
                  rel="noreferrer"
                  className="btn btn-primary ml-lg-4 mr-3 mr-md-4 mr-lg-0 d-none d-sm-block order-lg-3"
                >
                  Sign Out
                </Link>
              ) : (
                ``
              )}
            </div>
          </div>
        </section>

        <div className="bg-white">
          <div className="container">
            <div className="row">
              <div className="col">
                <ul className="nav nav-tabs border-0" id="myTab" role="tablist">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="resources-tab"
                      data-toggle="tab"
                      href="#resources"
                      role="tab"
                      aria-controls="resources"
                      aria-selected="true"
                    >
                      Patients{" "}
                    </a>
                  </li>

                  {/* <li className="nav-item">
                  <a
                    className="nav-link"
                    id="schedule-tab"
                    data-toggle="tab"
                    href="#schedule"
                    role="tab"
                    aria-controls="schedule"
                    aria-selected="false"
                  >
                    Schedule Appointment
                  </a>
                </li> */}
                  <li className="nav-item">
                    <a
                      className="nav-link "
                      id="profile-tab"
                      data-toggle="tab"
                      href="#profile"
                      role="tab"
                      aria-controls="profile"
                      aria-selected="false"
                    >
                      Profile
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <section className="bg-light tab-content pt-5">
          <div
            className="tab-pane fade show active"
            id="resources"
            role="tabpanel"
            aria-labelledby="resources-tab"
          >
            <div className="container">
              <div className="row mb-4 mb-md-5 align-items-start">
                <div className="col-lg-11 border-lg-right pr-lg-5">
                  {/* <div className="row mb-lg-5">
                  <div className="col">
                    <h3>Update Profile</h3>
                  </div>
                </div> */}
                  {PatientsData
                    ? PatientsData.data?.map((patient, index) => (
                        <Link
                          to={{
                            pathname: `/patient/${patient._id}`,
                            state: User,
                          }}
                          key={index}
                        >
                          <div className="card card-body px-3 py-4 flex-sm-row align-items-center justify-content-between hover-box-shadow mb-2 mb-sm-3">
                            <div className="col-lg-8 col-md-6">
                              <h6 className="mb-0">
                                {patient.Firstname} {patient.Lastname}
                              </h6>
                            </div>
                            <div className="col-lg-4 col-md-6 align-items-right">
                              <h6 className="mb-0">
                                {patient.PatientReviewed === true &&
                                patient.PatientRejected === true ? (
                                  <div className=" alert alert-warning align-items-center">
                                    REJECTED
                                  </div>
                                ) : patient.PatientReviewed === false &&
                                  patient.PatientRejected !== true ? (
                                  <div className="alert alert-warning align-items-center">
                                    {" "}
                                    PENDING REVIEW
                                  </div>
                                ) : (
                                  <div className="alert alert-success align-items-center">
                                    ACCEPTED
                                  </div>
                                )}
                              </h6>
                            </div>
                          </div>
                        </Link>
                      ))
                    : `Loading`}
                  {/* <Patients
                      patient={patient}
                      key={index}
                      onClick={fetchPatient}
                    /> */}
                </div>
              </div>
              <hr className="mb-4 mb-md-5" />
            </div>
          </div>
          <div
            className="tab-pane fade "
            id="profile"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            <Profile UserProfile={User.data} />
          </div>
          {/* <div
          className="tab-pane fade"
          id="schedule"
          role="tabpanel"
          aria-labelledby="schedule-tab"
        >
          <Schedule CurrentUser={User} />
        </div> */}
        </section>
      </Fragment>
    );
  } else {
    return "Loading";
  }
};
export default AdminAccount;
