import React from "react";
import { SelectField } from "./Select";
export const Question = (props) => {
  return (
    <div className="row form-row mb-1">
      <div className="col-sm-12">
        <SelectField
          FieldName={props.Question}
          Options={props.Options}
          MessageDesc={props.MessageDesc}
          InputID={props.InputID}
          onChange={props.onChange}
        />
      </div>
    </div>
  );
};
