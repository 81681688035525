export const ResourcesList = () => {
	const ResourceListItems = [
		{
			title: 'WELCOME LETTER',
			id: 'welcomeletter',
			link: '/downloads/1Welcome_letter.pdf',
		},
		{
			title: 'PSYCHIATRY, PSYCHIATRIST, AND PSYCHOLOGIST DOCUMENT',
			link: '/downloads/2Psychiatry_Psychiatrist_Psychologists.pdf',
			id: 'professionaldisclosure',
		},
		{
			title: 'TREATMENT OF MENTAL ILLNESS DOCUMENT',
			id: '3Treatment_of_Mental_illness',
			link: '/downloads/3Treatment_of_Mental_illness.pdf',
		},
		{
			title: 'PRIVACY NOTICE',
			id: '5Privacy_Notice',
			link: '/downloads/5Privacy_Notice.pdf',
		},
		{
			title:
				'STATE OF NJ DESIGNATED PSYCHIATRIC SCREENING AND CRISES CENTERS(for other counties please visit Resources web page on main website)',
			id: '6NJ_Designated Psychiatric Screening_Crises_Centers',
			link: '/downloads/MH_Screening_Centers_v4.pdf',
		},
		{
			title: 'CONSENT FOR TREATMENT FORM - Return to Clinic.',
			id: '7Consent_for_Treatment_Form',
			link: '/downloads/7Consent_for_Treatment_Form.pdf',
		},
		{
			title: 'PROFESSIONAL DISCLOSURE FORM - Return to Clinic.',
			id: '9Professional_Disclosure_Form',
			link: '/downloads/8Professional_Disclosure_Form.pdf',
		},
		{
			title: 'PATIENT INFORMATION FORM - Return to Clinic.',
			id: '10Patient_Information_Form',
			link: '/downloads/9Patient_Information_New.pdf',
		},
		{
			title: 'PATIENT HISTORY FORM - Return to Clinic.',
			id: '11Patient_History_Form',
			link: '/downloads/11Patient_History_Form.pdf',
		},
		{
			title: 'PSYCHOTROPIC MEDICATIONS CURRENT OR PAST - Return to Clinic.',
			id: '12Psychotropic_Medications_Current_or_ Past',
			link: '/downloads/12Psychotropic_Medications_Current_or_ Past.pdf',
		},
		{
			title: 'OFFICE POLICIES DOCUMENT - Return to Clinic.',
			id: '13Office_Policies_Document',
			link: '/downloads/13Office_Policies_Document.pdf',
		},
		{
			title: 'MEDICATION POLICIES DOCUMENT - Return to Clinic.',
			id: '14Medication_Policies_Document',
			link: '/downloads/14Medication_Policies_Document.pdf',
		},
		{
			title: 'HIPAA DISCLOSE FORM - Return to Clinic.',
			id: '15HIPAA_Disclose_Form',
			link: '/downloads/15HIPAA_Disclose_Form.pdf',
		},
		{
			title: 'HIPAA OBTAIN FORM - Return to Clinic.',
			id: '16HIPAA_Obtain_Form',
			link: '/downloads/16HIPAA_Obtain_Form.pdf',
		},
		{
			title:
				'RELEASE OF INFORMATION TO PRIMARY CARE PHYSICIAN FORM - Return to Clinic.',
			id: 'RELEASEOFINFO',
			link: '/downloads/17Release_of_Information_PCP.pdf',
		},
		{
			title:
				'FORMS_COMPLETION_LETTER (office mailing address can be cut out from this letter to affix to an envelope)',
			id: '19Forms_Completion_Letter',
			link: '/downloads/19Forms_Completion_Letter.pdf',
		},
	];

	return (
		<>
			{ResourceListItems.map((Items) => (
				<div className="card card-body px-3 py-4 flex-sm-row align-items-center justify-content-between hover-box-shadow mb-2 mb-sm-3">
					<div className="col-auto col-md">
						<h6 className="mb-0" key={Items.id}>
							<a href={Items.link} target="_blank" rel="noopener noreferrer">
								{Items.title}
							</a>
						</h6>
					</div>
				</div>
			))}
		</>
	);
};
