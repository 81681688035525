import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

export class Footer extends Component {
  render() {
    return (
      <Fragment>
        <footer className="bg-primary-3 text-white links-white pb-4 footer-1">
          <div className="container">
            <div className="row">
              <div className="col">
                <hr />
              </div>
            </div>
            <div className="row flex-column flex-lg-row align-items-center justify-content-center justify-content-lg-between text-center text-lg-left">
              <div className="col-auto">
                <div className="d-flex flex-column flex-sm-row align-items-center text-small">
                  <div className="text-muted">
                    &copy; 2022 BMB Consultants{" "}
                    <Link
                      to="https://www.google.com/policies/privacy/"
                      target="_blank"
                    >
                      Privacy Policy
                    </Link>{" "}
                    and{" "}
                    <Link
                      to="https://policies.google.com/terms"
                      target="_blank"
                    >
                      Terms of Service
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-auto mt-3 mt-lg-0">
                <ul className="list-unstyled d-flex mb-0">
                  <li className="mx-3">
                    <Link to="#" className="hover-fade-out">
                      <img
                        src="assets/img/icons/social/dribbble.svg"
                        alt="Dribbble"
                        className="icon icon-xs bg-white"
                        data-inject-svg
                      />
                    </Link>
                  </li>
                  <li className="mx-3">
                    <Link to="#" className="hover-fade-out">
                      <img
                        src="assets/img/icons/social/twitter.svg"
                        alt="Twitter"
                        className="icon icon-xs bg-white"
                        data-inject-svg
                      />
                    </Link>
                  </li>
                  <li className="mx-3">
                    <Link to="#" className="hover-fade-out">
                      <img
                        src="assets/img/icons/social/github.svg"
                        alt="Github"
                        className="icon icon-xs bg-white"
                        data-inject-svg
                      />
                    </Link>
                  </li>
                  <li className="mx-3">
                    <Link to="#" className="hover-fade-out">
                      <img
                        src="assets/img/icons/social/facebook.svg"
                        alt="Facebook"
                        className="icon icon-xs bg-white"
                        data-inject-svg
                      />
                    </Link>
                  </li>
                  <li className="mx-3">
                    <Link to="#" className="hover-fade-out">
                      <img
                        src="assets/img/icons/social/google.svg"
                        alt="Google"
                        className="icon icon-xs bg-white"
                        data-inject-svg
                      />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </footer>
        <Link
          to="#top"
          className="btn btn-primary rounded-circle btn-back-to-top"
          data-smooth-scroll
          data-aos="fade-up"
          data-aos-offset="2000"
          data-aos-mirror="true"
          data-aos-once="false"
        >
          <img
            src="assets/img/icons/interface/icon-arrow-up.svg"
            alt="Icon"
            className="icon bg-white"
            data-inject-svg
          />
        </Link>
      </Fragment>
    );
  }
}

export default Footer;
