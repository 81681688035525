import { useState } from "react";
import { InputField } from "../../../assets/InputField";
import { SelectField } from "../../../assets/Select";
import { payer } from "../../../assets/Questions";
import { updateUser } from "../../../../utils/auth";

const Profile = ({ UserProfile }) => {
  const [User, setUser] = useState(UserProfile);
  const [error, setError] = useState("");
  // const currentgender = [
  //   { name: "Male", value: "male" },
  //   { name: "Female", value: "female" },
  //   { name: "Transgender", value: "trans" },
  //   { name: "None of the above", value: "none" },
  // ];
  // console.log("User State ====", User, 6);
  // console.log("Current User Object ====", UserProfile);
  const [submit, setSubmit] = useState(false);

  const handleChange = (e) => {
    const value = e.target.value;
    setUser({
      ...User,
      [e.target.name]: value,
    });
  };

  const updateProfile = async (e) => {
    e.preventDefault();

    let updatedUser = {
      _id: setUser._id,
      params: {
        Email: setUser?.emailaddress,
        Password: setUser?.Password,
        MobilePhonenumber: setUser?.MobilePhonenumber,
        HomePhonenumber: setUser?.HomePhonenumber,
        PatientData: {
          // currentgender: setUser?.PatientData?.currentgender,
          payer: setUser?.PatientData?.payer,
          medicalinsurancecompany:
            setUser?.PatientData?.medicalinsurancecompany,
          subscribername: setUser?.PatientData?.subscribername,
          memberidentificationnumber:
            setUser?.PatientData?.memberidentificationnumber,
          streetaddress: setUser?.PatientData?.streetaddress,
          apartmentsuite: setUser?.PatientData?.apartmentsuite,
          city: setUser?.PatientData?.city,
        },
      },
    };

    console.log(updatedUser);
    setSubmit(true);

    const response = await updateUser({
      updatedUser,
    });

    const { message } = response.data;

    const status = response ? response.status : null;

    if (status === 403 || status === 401) {
      setSubmit(false);
      setError(message);
    }
    return response;
  };

  return (
    <div className="container">
      <div className="row mb-4 mb-md-5 align-items-start">
        <div className="col-lg-4 order-lg-2 mb-4 mb-lg-0 sticky-lg-top pl-lg-5">
          <div className="alert alert-primary">
            Your information is always kept encrypted and can never be accessed
            by third parties.
            {/* <a href="#">View our Privacy Policy</a> */}
          </div>
        </div>
        <div className="col-lg-8 border-lg-right pr-lg-5">
          <div className="row mb-lg-5">
            <div className="col">
              <h3>Update Profile</h3>
            </div>
          </div>
          <form onSubmit={(e) => updateProfile(e)}>
            <div className="form-group">
              <label for="Email">Email Address</label>
              <input
                type="text"
                className="form-control"
                id="Email"
                name="Email"
                value={User.Email}
                onChange={(e) => handleChange(e)}
              />
            </div>
            {/* <div className="form-group">
              <SelectField
                FieldName="Current Identified Gender"
                Options={currentgender}
                InputID={currentgender}
                onChange={(e) => handleChange(e)}
                value={
                  !setUser.PatientData.currentgender
                    ? User.PatientData.currentgender
                    : setUser.PatientData.currentgender
                }
              />
            </div> */}
            <div className="form-group">
              <label for="MobilePhonenumber">Mobile Phone Number</label>
              <input
                type="text"
                id="MobilePhonenumber"
                name="MobilePhonenumber"
                className="form-control"
                value={User.MobilePhonenumber}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className="form-group">
              <label for="Phonenumber">Home Number</label>
              <input
                type="text"
                id="HomePhonenumber"
                name="HomePhonenumber"
                className="form-control"
                value={User.HomePhonenumber}
                onChange={(e) => handleChange(e)}
              />
            </div>

            <div className="row form-row">
              <div className="form-group col-sm-12">
                <label for="streetaddress">Street Address</label>
                <input
                  type="text"
                  id="streetaddress"
                  name="streetaddress"
                  className="form-control"
                  value={User.PatientData.streetaddress}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div className="form-group col-sm-6">
                <label for="apartmentsuite">Apartment Suite</label>
                <input
                  type="text"
                  id="apartmentsuite"
                  name="apartmentsuite"
                  className="form-control"
                  value={User.PatientData.apartmentsuite}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div className="form-group col-sm-6">
                <label for="city">City</label>
                <input
                  type="text"
                  id="city"
                  name="city"
                  className="form-control"
                  value={User.PatientData.city}
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </div>
            <div className="form-group">
              <SelectField
                FieldName="Who will be payming for the cost of your treatment?"
                Options={payer}
                key={payer}
                InputID={payer}
                onChange={(e) => handleChange(e)}
                value={User.PatientData.payer}
              />
            </div>
            {(User.PatientData.payer &&
              User.PatientData.payer === "PrivateInsurance") ||
            (User.PatientData.payer &&
              User.PatientData.payer === "UnionInsurance") ||
            (User.PatientData.payer && User.PatientData.payer === "MedicAid") ||
            (User.PatientData.payer &&
              User.PatientData.payer === "MedicCare") ? (
              <div className="row form-row">
                <div className="col-sm-12">
                  <InputField
                    InputType="text"
                    FieldName="Subscriber First and Last Name (If you are not the subscriber)"
                    InputID="subscribername"
                    onChange={(e) => handleChange(e)}
                    value={User.PatientData.subscribername}
                  />
                </div>
                <div className="col-sm-6">
                  <InputField
                    FieldName="Name of Medical Insurance Company"
                    InputType="text"
                    InputID="medicalinsurancecompany"
                    onChange={(e) => handleChange(e)}
                    value={User.PatientData.medicalinsurancecompany}
                  />
                </div>

                <div className="col-sm-6">
                  <InputField
                    FieldName="Member Identification Number"
                    InputType="text"
                    InputID="memberidentificationnumber"
                    value={User.PatientData.memberidentificationnumber}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>
            ) : (
              ""
            )}

            {submit ? (
              <button className="btn btn-primary " disabled type="submit">
                Loading
              </button>
            ) : (
              <button className="btn btn-primary" type="submit">
                Save Changes
              </button>
            )}
          </form>
        </div>
      </div>
      <hr className="mb-4 mb-md-5" />
      {/* <div className="row mb-4 mb-md-5 align-items-start">
        <div className="col-lg-4 order-lg-2 mb-4 mb-lg-0 sticky-lg-top pl-lg-5">
          <div className="alert alert-primary">
            Some of this information will appear on your public profile. A
            detailed public profile helps users with similar professional
            interests and location to connect with you.
            <a href="#">View your Public Profile</a>
          </div>
        </div>
        <div className="col-lg-8 border-lg-right pr-lg-5">
          <div className="row mb-lg-5">
            <div className="col">
              <h3>Profile Information</h3>
            </div>
          </div>
          <form>
            <div className="row form-row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label for="city">City</label>
                  <input
                    type="text"
                    className="form-control"
                    id="city"
                    value="Melbourne"
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label for="country">Country</label>
                  <input
                    type="text"
                    className="form-control"
                    id="country"
                    value="Australia"
                  />
                </div>
              </div>
            </div>
            <div className="row form-row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label for="birthday">Birthday</label>
                  <input
                    type="text"
                    id="birthday"
                    className="form-control"
                    data-flatpickr="data-flatpickr"
                    data-alt-input="true"
                    placeholder="Select a date"
                  />
                  <small>This will never be made public</small>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label for="country">Website</label>
                  <input type="url" className="form-control" id="website" />
                </div>
              </div>
            </div>
            <div className="form-group">
              <label for="username">Twitter Handle</label>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">@</span>
                </div>
                <input
                  className="form-control"
                  placeholder="Username"
                  type="text"
                />
              </div>
            </div>
            <div className="form-group">
              <label for="bio">Profile Text</label>
              <textarea id="bio" rows="6" className="form-control"></textarea>
            </div>
            <button className="btn btn-primary" disabled="disabled" type="submit">
              Save changes
            </button>
          </form>
        </div>
      </div> */}
    </div>
  );
};
export default Profile;
