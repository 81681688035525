import React, { Fragment, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { verifyUser } from "../../../../utils/auth";

const VerifyToken = () => {
  const [state, setState] = useState({
    Token: "",
    UserID: "",
  });

  const [submit, setSubmit] = useState(false);
  const [error, setError] = useState("");

  const navigate = useHistory();
  const handleChange = (e) => {
    const value = e.target.value;
    setState({
      ...state,
      [e.target.name]: value,
    });
  };

  const handleSubmit = async (e) => {
    // httpURL
    //   .post("auth/login/", { data })
    //   .((res) => {
    //     console.log(res);
    //     console.log(res.data);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    e.preventDefault();
    try {
      const Token = state.Token;
      const UserID = state.UserID;
      const userData = { Token: Token, UserId: UserID };
      setSubmit(true);
      const response = await verifyUser({ userData, navigate });
      const { message } = response.data;

      const status = response ? response.status : null;

      if (status === 403 || status === 401) {
        setSubmit(false);
        setError(message);
      } else {
        return response;
      }
    } catch (error) {
      setSubmit(false);

      return error;
    }
  };

  return (
    <Fragment>
      <div
        data-overlay
        className="min-vh-100 bg-light d-flex flex-column justify-content-md-center"
      >
        <section className="py-3">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-4 col-lg-5 col-md-6">
                <div className="card card-body shadow">
                  <h1 className="h5 text-center">Account Verification</h1>
                  <form onSubmit={(e) => handleSubmit(e)}>
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        name="Token"
                        placeholder="Enter Token"
                        onChange={(e) => handleChange(e)}
                      />
                    </div>

                    {/* <div className="text-right text-small mt-2">
                      <Link to="/forgot-password">Forgot Password?</Link>
                    </div> */}

                    {/* <div className="form-group">
                      <div className="custom-control custom-checkbox text-small">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="sign-in-remember"
                        />
                        <label
                          className="custom-control-label"
                          for="sign-in-remember"
                        >
                          Remember me next time
                        </label>
                      </div>
                    </div> */}
                    {/* <Link className="btn btn-primary btn-block" to="/account">
                      Sign In
                    </Link> */}

                    {error ? (
                      <div className="alert alert-primary">{error}</div>
                    ) : (
                      ""
                    )}
                    {submit ? (
                      <button
                        className="btn btn-primary btn-block"
                        disabled
                        type="submit"
                      >
                        Loading
                      </button>
                    ) : (
                      <button
                        className="btn btn-primary btn-block"
                        type="submit"
                      >
                        Verify Token
                      </button>
                    )}
                  </form>
                </div>
                <div className="text-center text-small mt-3">
                  Have an account? <Link to="/login">Login here</Link>
                </div>
                {/* <div className="text-center text-small mt-3">
                  Don't have an account?{" "}
                  <Link to="/sign-up">Get started here</Link>
                </div> */}
              </div>
            </div>
          </div>
        </section>
      </div>
    </Fragment>
  );
};
export default VerifyToken;
