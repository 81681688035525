import React from "react";

export const TextArea = (props) => {
  // console.log(props);
  return (
    <div className="form-group">
      <label htmlfor={props.InputID}>{props.FieldName}</label>
      <textarea
        id={props.InputID}
        rows={props.rows}
        name={props.InputID}
        className="form-control"
        onChange={props.onChange}
      ></textarea>
      <small>{props.MessageDesc}</small>
    </div>
  );
};
