import React, { Fragment, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { logIn, logOut } from "../../../../utils/auth";
const Authentication = () => {
  const navigate = useHistory();

  useEffect(() => {
    const isAuthenticated = localStorage.getItem("isAuthenticated");

    if (isAuthenticated) {
      logOut();
    }
  });

  const [state, setState] = useState({
    Email: "",
    Password: "",
  });

  const [submit, setSubmit] = useState(false);
  const [error, setError] = useState("");

  const handleChange = (e) => {
    const value = e.target.value;
    setState({
      ...state,
      [e.target.name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const Email = state.Email;
    const Password = state.Password;
    const userData = { Email: Email, Password: Password };
    setSubmit(true);
    const response = await logIn({ userData, navigate });
    const { message } = response.data;

    const status = response ? response.status : null;

    if (status === 403 || status === 401) {
      setSubmit(false);
      setError(message);
    } else {
      setSubmit(true);
      return response;
    }
  };

  return (
    <Fragment>
      <div
        data-overlay
        className="min-vh-100 bg-light d-flex flex-column justify-content-md-center"
      >
        <section className="py-3">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-4 col-lg-5 col-md-6">
                <div className="card card-body shadow">
                  <h1 className="h5 text-center">Sign In</h1>
                  <form onSubmit={(e) => handleSubmit(e)}>
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        name="Email"
                        placeholder="Email Address"
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control"
                        name="Password"
                        placeholder="Password"
                        onChange={(e) => handleChange(e)}
                      />
                      {/* <div className="text-right text-small mt-2">
                      <Link to="/forgot-password">Forgot Password?</Link>
                    </div> */}
                    </div>
                    {/* <div className="form-group">
                      <div className="custom-control custom-checkbox text-small">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="sign-in-remember"
                        />
                        <label
                          className="custom-control-label"
                          for="sign-in-remember"
                        >
                          Remember me next time
                        </label>
                      </div>
                    </div> */}
                    {/* <Link className="btn btn-primary btn-block" to="/account">
                      Sign In
                    </Link> */}

                    {error ? (
                      <div className="alert alert-primary">{error}</div>
                    ) : (
                      ""
                    )}
                    <button className="btn btn-primary btn-block" type="submit">
                      {submit ? "Loading" : "Sign In"}
                    </button>
                  </form>
                </div>
                <div className="text-center text-small mt-3">
                  Have an account?{" "}
                  <Link to="/forgot-password">Forgot Password ?</Link>
                </div>
                <div className="text-center text-small mt-3">
                  Don't have an account?{" "}
                  <Link to="/sign-up">Get started here</Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Fragment>
  );
};
export default Authentication;
