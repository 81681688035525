import React, { Fragment } from "react";

/**
 * Loads once a user completes registration
 */
const Welcome = () => {
  return (
    <Fragment>
      <div className="bg-primary-3 o-hidden" data-overlay>
        <section className="text-white pb-0">
          <div className="container pb-5">
            <div className="row justify-content-center text-center">
              <div className="col-md-9 col-lg-8 col-xl-9">
                <h1 className="display-3">Welcome!</h1>
                <p className="lead mb-5">
                  Thank you, for completing the Initial Screening Assessment.{" "}
                </p>
              </div>
            </div>
          </div>
          {/*<div
            className="position-absolute w-50 h-100 top left"
            data-jarallax-element="100 50"
          >
            <div className="blob blob-4 bg-gradient w-100 h-100 top left"></div>
          </div>
           <div className="divider divider-bottom bg-white"></div> */}
        </section>
      </div>
      <section>
        <div className="container">
          <div className="row align-items-center justify-content-around text-center text-lg-left">
            <div
              className="col-md-9 col-lg-9 col-xl-9 mb-4 mb-md-5 mb-lg-0 order-lg-2"
              data-aos="fade-in"
            >
              <h6 className="h3">
                Kindly read the information below carefully.
              </h6>
              <ul className="lead">
                <li className="pb-3">
                  Within the next 24 hours (or close of next business day if
                  completing the assessment on a weekend day), you will receive
                  an email from Intake Coordinator at BMB Consultants P.C. with
                  a decision as whether you have been approved for intake.
                </li>{" "}
                <li>
                  After you receive this acceptance email, you will be able to
                  use the username (email address) and password you entered
                  while completing of Intake screening assessment to log on to
                  the “Current Patients” section of our website to begin the
                  onboarding process.
                </li>{" "}
                <li>
                  Please, if you are having psychiatric or medical emergency,
                  please CALL 911 or go directly to your nearest emergency
                  department if safe to do so.
                </li>{" "}
                <li>
                  Thank you again for completing the Intake Screening
                  Assessment.
                </li>
              </ul>{" "}
            </div>
            {/* <div
              className="col-md-9 col-lg-6 col-xl-4 order-lg-1"
              data-aos="fade-in"
            >
              <img
                src="assets/img/about-2.jpg"
                alt="About"
                className="img-fluid rounded shadow"
              />
            </div> */}
          </div>
        </div>
      </section>
    </Fragment>
  );
};
export default Welcome;
