import React, { Fragment, useEffect, useState } from "react";
import { acceptPatient, getUser, rejectPatient } from "../../utils/auth";
import { Link } from "react-router-dom";

/**
 * Loads once a user completes registration
 */
const Patient = ({ match }) => {
  const [PatientData, setPatientData] = useState("");
  const { id } = match.params;

  // const navigate = useHistory();

  // console.log(id, "patient id");
  // useEffect(() => {
  //   getUser(id).then((patient) => setPatientData(patient));
  // }, [id]);

  useEffect(() => {
    getUser(id).then((user) => setPatientData(user));
  }, [id]);

  const { data } = PatientData;
  // console.log(data);
  // console.log(PatientInfo);

  const handleAcceptPatient = (e, data) => {
    // console.log(data._id, "accept event");

    e.preventDefault();
    acceptPatient(data._id);
    window.location.reload();
  };
  const handleRejectPatient = (e, data) => {
    // console.log(data._id, "reject event");

    e.preventDefault();
    rejectPatient(data._id);
    window.location.reload();
  };

  if (PatientData) {
    return (
      <Fragment>
        <section className="pt-5">
          <div className="container">
            <div className="row justify-content-end sticky-top bg-white">
              <div className="col-lg-3 col-4 py-3 py-md-4 border-bottom">
                <div className="d-flex flex-column align-items-center text-center">
                  <Link
                    to="/admin"
                    className="btn btn-outline-primary btn-block"
                  >
                    <span>Back to Admin Dashboard</span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <div className="pricing-table-section text-center text-lg-left">
                <div className="row no-gutters">
                  <div className="col">
                    <h5 className="mb-4">Personal Information</h5>
                  </div>
                </div>
                <div className="border rounded">
                  <div className="row no-gutters align-items-center">
                    <div className="col-12 col-lg-3  py-3 py-md-4">
                      <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                        <h6 className="mb-0 ml-lg-4">First Name</h6>
                      </div>
                    </div>
                    <div className="col-6 col-lg-4 d-flex justify-content-center py-3 py-md-4">
                      <span>{data.Firstname}</span>
                    </div>
                  </div>
                  <div className="row no-gutters align-items-center">
                    <div className="col-12 col-lg-3  py-3 py-md-4">
                      <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                        <h6 className="mb-0 ml-lg-4">Last Name</h6>
                      </div>
                    </div>
                    <div className="col-6 col-lg-4 d-flex justify-content-center py-3 py-md-4">
                      <span>{data.Lastname}</span>
                    </div>
                  </div>
                  <div className="row no-gutters align-items-center">
                    <div className="col-12 col-lg-3  py-3 py-md-4">
                      <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                        <h6 className="mb-0 ml-lg-4">
                          Preferred to be addressed as
                        </h6>
                      </div>
                    </div>
                    <div className="col-6 col-lg-4 d-flex justify-content-center py-3 py-md-4">
                      <span>{data.PatientData.preferredtobeaddressedas}</span>
                    </div>
                  </div>
                  <div className="row no-gutters align-items-center">
                    <div className="col-12 col-lg-3  py-3 py-md-4">
                      <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                        <h6 className="mb-0 ml-lg-4">Date of Birth </h6>
                      </div>
                    </div>
                    <div className="col-6 col-lg-4 d-flex justify-content-center py-3 py-md-4">
                      <span>{data.PatientData.dateofbirth}</span>
                    </div>
                  </div>
                  <div className="row no-gutters align-items-center">
                    <div className="col-12 col-lg-3  py-3 py-md-4">
                      <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                        <h6 className="mb-0 ml-lg-4">Gender at Birth </h6>
                      </div>
                    </div>
                    <div className="col-6 col-lg-4 d-flex justify-content-center py-3 py-md-4">
                      <span>{data.PatientData.genderatbirth}</span>
                    </div>
                  </div>
                  <div className="row no-gutters align-items-center">
                    <div className="col-12 col-lg-3  py-3 py-md-4">
                      <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                        <h6 className="mb-0 ml-lg-4">Current Gender </h6>
                      </div>
                    </div>
                    <div className="col-6 col-lg-4 d-flex justify-content-center py-3 py-md-4">
                      <span>{data.PatientData.currentgender}</span>
                    </div>
                  </div>
                  <div className="row no-gutters align-items-center">
                    <div className="col-12 col-lg-3  py-3 py-md-4">
                      <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                        <h6 className="mb-0 ml-lg-4">Address: </h6>
                      </div>
                    </div>
                    <div className="col-6 col-lg-6 d-flex justify-content-center py-3 py-md-4">
                      <ul className="">
                        <li>
                          {" "}
                          Street Address: {data.PatientData.streetaddress}
                        </li>
                        <li>
                          Apartment Suite: {data.PatientData.apartmentsuite}
                        </li>
                        <li>County: {data.PatientData.county}</li>
                        <li>City: {data.PatientData.city}</li>
                        <li>State: {data.PatientData.state}</li>
                        <li>Zip Code: {data.PatientData.zipcode}</li>
                      </ul>
                    </div>
                  </div>
                  <div className="row no-gutters align-items-center">
                    <div className="col-12 col-lg-3  py-3 py-md-4">
                      <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                        <h6 className="mb-0 ml-lg-4">Contact: </h6>
                      </div>
                    </div>
                    <div className="col-6 col-lg-6 d-flex justify-content-center py-3 py-md-4">
                      <ul className="">
                        <li> Email Address: {data.Email}</li>
                        <li>Home Phone Number: {data.HomePhonenumber}</li>
                        <li>Mobile Phone Number: {data.MobilePhonenumber}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <div className="pricing-table-section text-center text-lg-left">
                <div className="row no-gutters">
                  <div className="col">
                    <h5 className="mb-4">Screening Assessment Response</h5>
                  </div>
                </div>
                <div className="border rounded">
                  <div className="row no-gutters align-items-center">
                    <div className="col-12 col-lg-3  py-3 py-md-4">
                      <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                        <h6 className="mb-0 ml-lg-4">Referred From</h6>
                      </div>
                    </div>
                    <div className="col-6 col-lg-4 d-flex justify-content-center py-3 py-md-4">
                      <span>{data.PatientData.referredby}</span>
                    </div>
                  </div>
                  <div className="row no-gutters align-items-center">
                    <div className="col-12 col-lg-3  py-3 py-md-4">
                      <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                        <h6 className="mb-0 ml-lg-4">Payer</h6>
                      </div>
                    </div>
                    <div className="col-6 col-lg-4 d-flex justify-content-center py-3 py-md-4">
                      <span>{data.PatientData.payer}</span>
                    </div>
                  </div>
                  <div className="row no-gutters align-items-center">
                    <div className="col-12 col-lg-3  py-3 py-md-4">
                      <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                        <h6 className="mb-0 ml-lg-4">
                          Reason for seeking Psychiatrist{" "}
                        </h6>
                      </div>
                    </div>
                    <div className="col-6 col-lg-4 d-flex justify-content-center py-3 py-md-4">
                      <span>{data.PatientData.psychhelpreason}</span>
                    </div>
                  </div>
                  <div className="row no-gutters align-items-center">
                    <div className="col-12 col-lg-3  py-3 py-md-4">
                      <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                        <h6 className="mb-0 ml-lg-4">
                          Are you currently experiencing thoughts of harming
                          yourself?{" "}
                        </h6>
                      </div>
                    </div>
                    <div className="col-6 col-lg-4 d-flex justify-content-center py-3 py-md-4">
                      <span>
                        {data.PatientData.screeningresponses.question1}
                      </span>
                    </div>
                  </div>
                  <div className="row no-gutters align-items-center">
                    <div className="col-12 col-lg-3  py-3 py-md-4">
                      <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                        <h6 className="mb-0 ml-lg-4">
                          Any past suicidal attempts?{" "}
                        </h6>
                      </div>
                    </div>
                    <div className="col-6 col-lg-4 d-flex justify-content-center py-3 py-md-4">
                      <span>
                        {data.PatientData.screeningresponses.question2}
                      </span>
                    </div>
                  </div>
                  <div className="row no-gutters align-items-center">
                    <div className="col-12 col-lg-3  py-3 py-md-4">
                      <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                        <h6 className="mb-0 ml-lg-4">
                          Are you currently experiencing thoughts of harming
                          others?
                        </h6>
                      </div>
                    </div>
                    <div className="col-6 col-lg-4 d-flex justify-content-center py-3 py-md-4">
                      <span>
                        {data.PatientData.screeningresponses.question3}
                      </span>
                    </div>
                  </div>
                  <div className="row no-gutters align-items-center">
                    <div className="col-12 col-lg-3  py-3 py-md-4">
                      <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                        <h6 className="mb-0 ml-lg-4">
                          Any past assaultive behaviors towards others or
                          history of harming others?
                        </h6>
                      </div>
                    </div>
                    <div className="col-6 col-lg-4 d-flex justify-content-center py-3 py-md-4">
                      <span>
                        {data.PatientData.screeningresponses.question4}
                      </span>
                    </div>
                  </div>
                  <div className="row no-gutters align-items-center">
                    <div className="col-12 col-lg-3  py-3 py-md-4">
                      <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                        <h6 className="mb-0 ml-lg-4">
                          Are you currently hearing voices that no one else
                          hears?
                        </h6>
                      </div>
                    </div>
                    <div className="col-6 col-lg-4 d-flex justify-content-center py-3 py-md-4">
                      <span>
                        {data.PatientData.screeningresponses.question5}
                      </span>
                    </div>
                  </div>
                  <div className="row no-gutters align-items-center">
                    <div className="col-12 col-lg-3  py-3 py-md-4">
                      <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                        <h6 className="mb-0 ml-lg-4">
                          Are you currently seeing things that no one else sees?
                        </h6>
                      </div>
                    </div>
                    <div className="col-6 col-lg-4 d-flex justify-content-center py-3 py-md-4">
                      <span>
                        {data.PatientData.screeningresponses.question6}
                      </span>
                    </div>
                  </div>
                  <div className="row no-gutters align-items-center">
                    <div className="col-12 col-lg-3  py-3 py-md-4">
                      <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                        <h6 className="mb-0 ml-lg-4">
                          Are your currently feeling that people are after you
                          and watching you?{" "}
                        </h6>
                      </div>
                    </div>
                    <div className="col-6 col-lg-4 d-flex justify-content-center py-3 py-md-4">
                      <span>
                        {data.PatientData.screeningresponses.question7}
                      </span>
                    </div>
                  </div>
                  <div className="row no-gutters align-items-center">
                    <div className="col-12 col-lg-3  py-3 py-md-4">
                      <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                        <h6 className="mb-0 ml-lg-4">
                          Are you currently feeling unsafe and threatened?{" "}
                        </h6>
                      </div>
                    </div>
                    <div className="col-6 col-lg-4 d-flex justify-content-center py-3 py-md-4">
                      <span>
                        {data.PatientData.screeningresponses.question8}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <div className="pricing-table-section text-center text-lg-left">
                <div className="row no-gutters">
                  <div className="col">
                    <h5 className="mb-4">Alcohol, Substance or Tobacco Use</h5>
                  </div>
                </div>
                <div className="border rounded">
                  <div className="row no-gutters align-items-center">
                    <div className="col-12 col-lg-6  py-3 py-md-4">
                      <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                        <h6 className="mb-0 ml-lg-4">
                          Are you currently using heroin or misusing
                          prescription pain medication such as Roxicodone,
                          Tramadol, Hydrocodone (Vicodin®), Oxycodone
                          (OxyContin®, Percocet®), Oxymorphone (Opana®),
                          Morphine (Kadian®, Avinza®), Hydromorphone,
                          (Dilaudid®), Codeine, Fentanyl, Tapentadol, Methadone,
                          Buprenorphine (Suboxone or Subutex)?
                        </h6>
                      </div>
                    </div>
                    <div className="col-6 col-lg-4 d-flex justify-content-center py-3 py-md-4">
                      <span>
                        {data.PatientData.screeningresponses.question14}
                      </span>
                    </div>
                  </div>
                  <div className="row no-gutters align-items-center">
                    <div className="col-12 col-lg-6  py-3 py-md-4">
                      <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                        <h6 className="mb-0 ml-lg-4">
                          Are you currently using cocaine or methamphetamine or
                          ecstasy etc?
                        </h6>
                      </div>
                    </div>
                    <div className="col-6 col-lg-4 d-flex justify-content-center py-3 py-md-4">
                      <span>
                        {data.PatientData.screeningresponses.question15}
                      </span>{" "}
                    </div>
                  </div>
                  <div className="row no-gutters align-items-center">
                    <div className="col-12 col-lg-6  py-3 py-md-4">
                      <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                        <h6 className="mb-0 ml-lg-4">
                          Are currently misusing any prescription medications?{" "}
                        </h6>
                      </div>
                    </div>
                    <div className="col-6 col-lg-4 d-flex justify-content-center py-3 py-md-4">
                      <span>
                        {data.PatientData.screeningresponses.question16}
                      </span>{" "}
                    </div>
                  </div>
                  <div className="row no-gutters align-items-center">
                    <div className="col-12 col-lg-6  py-3 py-md-4">
                      <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                        <h6 className="mb-0 ml-lg-4">
                          Are you currently misusing alcohol? If female, 4 or
                          more drinks/day or if male, more than 5 or more
                          drinks/day
                        </h6>
                      </div>
                    </div>
                    <div className="col-6 col-lg-6 d-flex justify-content-center py-3 py-md-4">
                      <span>
                        {data.PatientData.screeningresponses.question17}
                      </span>
                    </div>
                  </div>
                  <div className="row no-gutters align-items-center">
                    <div className="col-12 col-lg-6  py-3 py-md-4">
                      <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                        <h6 className="mb-0 ml-lg-4">
                          Any past suicidal attempts?{" "}
                        </h6>
                      </div>
                    </div>
                    <div className="col-6 col-lg-4 d-flex justify-content-center py-3 py-md-4">
                      <span>
                        {data.PatientData.screeningresponses.question18}
                      </span>
                    </div>
                  </div>
                  <div className="row no-gutters align-items-center">
                    <div className="col-12 col-lg-6  py-3 py-md-4">
                      <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                        <h6 className="mb-0 ml-lg-4">
                          Do you currently smoke marihuana/cannabis or cannabis
                          derivatives such cannabinoid in any form, smoking, in
                          form of gummies or CBD oil or pills etc?
                        </h6>
                      </div>
                    </div>
                    <div className="col-6 col-lg-6 d-flex justify-content-center py-3 py-md-4">
                      <span>
                        {data.PatientData.screeningresponses.question20}
                      </span>
                    </div>
                  </div>
                  <div className="row no-gutters align-items-center">
                    <div className="col-12 col-lg-6  py-3 py-md-4">
                      <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                        <h6 className="mb-0 ml-lg-4">
                          Are you currently or within the last 6 months been
                          prescribed any of the following medications such as
                          Valium (diazepam), Xanax (alprazolam), Ativan
                          (lorazepam), Serax (oxazepam), Klonopin (clonazepam),
                          Librium (Chlordiazepoxide), Restoril (Temazepam)
                        </h6>
                      </div>
                    </div>
                    <div className="col-6 col-lg-6 d-flex justify-content-center py-3 py-md-4">
                      <span>
                        {data.PatientData.screeningresponses.question20}
                      </span>
                    </div>
                  </div>
                  <div className="row no-gutters align-items-center">
                    <div className="col-12 col-lg-6  py-3 py-md-4">
                      <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                        <h6 className="mb-0 ml-lg-4">
                          Are you currently on any prescription opioid pain
                          medications such as Hydrocodone (Vicodin®), Oxycodone
                          (OxyContin®, Percocet®), Oxymorphone (Opana®),
                          Morphine (Kadian®, Avinza®), Hydromorphone,
                          (Dilaudid®), Codeine, Fentanyl, Tapentadol, Methadone,
                          Buprenorphine (Suboxone or Subutex) ?
                        </h6>
                      </div>
                    </div>
                    <div className="col-6 col-lg-6 d-flex justify-content-center py-3 py-md-4">
                      <span>
                        {data.PatientData.screeningresponses.question21}
                      </span>
                    </div>
                  </div>
                  <div className="row no-gutters align-items-center">
                    <div className="col-12 col-lg-6  py-3 py-md-4">
                      <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                        <h6 className="mb-0 ml-lg-4">
                          Are you currently smoking cigarettes or cigars or
                          vaping nicotine?{" "}
                        </h6>
                      </div>
                    </div>
                    <div className="col-6 col-lg-6 d-flex justify-content-center py-3 py-md-4">
                      <span>
                        {data.PatientData.screeningresponses.question22}
                      </span>
                    </div>
                  </div>
                  <div className="row no-gutters align-items-center">
                    <div className="col-12 col-lg-6  py-3 py-md-4">
                      <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                        <h6 className="mb-0 ml-lg-4">
                          Are you interested in quitting use of tobacco products
                          or nicotine?
                        </h6>
                      </div>
                    </div>
                    <div className="col-6 col-lg-4 d-flex justify-content-center py-3 py-md-4">
                      <span>
                        {data.PatientData.screeningresponses.question23}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <div className="pricing-table-section text-center text-lg-left">
                <div className="row no-gutters">
                  <div className="col">
                    <h5 className="mb-4">Physical Health Issues</h5>
                  </div>
                </div>
                <div className="border rounded">
                  <div className="row no-gutters align-items-center">
                    <div className="col-12 col-lg-6  py-3 py-md-4">
                      <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                        <h6 className="mb-0 ml-lg-4">
                          Are you currently, experiencing any of the following
                          acute physical health symptoms such as sudden acute
                          chest pain or tightness, neck, back, or arms, abnormal
                          or very fast heart beats
                        </h6>
                      </div>
                    </div>
                    <div className="col-6 col-lg-4 d-flex justify-content-center py-3 py-md-4">
                      <span>
                        {data.PatientData.screeningresponses.question24}
                      </span>
                    </div>
                  </div>
                  <div className="row no-gutters align-items-center">
                    <div className="col-12 col-lg-6  py-3 py-md-4">
                      <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                        <h6 className="mb-0 ml-lg-4">
                          Are you currently, experiencing any of the following
                          acute physical health symptoms such numbness/weakness
                          on one side of your face or arms or legs, sudden
                          change in your voice or unable to speak, sudden change
                          in your facial appearance or facial drooping, or
                          lightheadedness or dizziness, feeling confused or
                          disorientated or severe headache?
                        </h6>
                      </div>
                    </div>
                    <div className="col-6 col-lg-4 d-flex justify-content-center py-3 py-md-4">
                      <span>
                        {data.PatientData.screeningresponses.question25}
                      </span>{" "}
                    </div>
                  </div>
                  <div className="row no-gutters align-items-center">
                    <div className="col-12 col-lg-6  py-3 py-md-4">
                      <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                        <h6 className="mb-0 ml-lg-4">
                          Are you currently, having trouble breathing, and feel
                          you are suffocating?{" "}
                        </h6>
                      </div>
                    </div>
                    <div className="col-6 col-lg-4 d-flex justify-content-center py-3 py-md-4">
                      <span>
                        {data.PatientData.screeningresponses.question26}
                      </span>{" "}
                    </div>
                  </div>
                  <div className="row no-gutters align-items-center">
                    <div className="col-12 col-lg-6  py-3 py-md-4">
                      <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                        <h6 className="mb-0 ml-lg-4">
                          Are you currently, having a fever or vomiting or
                          experiencing acute abdominal pain?
                        </h6>
                      </div>
                    </div>
                    <div className="col-6 col-lg-6 d-flex justify-content-center py-3 py-md-4">
                      <span>
                        {data.PatientData.screeningresponses.question27}
                      </span>
                    </div>
                  </div>
                  <div className="row no-gutters align-items-center">
                    <div className="col-12 col-lg-6  py-3 py-md-4">
                      <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                        <h6 className="mb-0 ml-lg-4">
                          Have you or are you currently being treated for any of
                          the following physical health problems? Select all
                          that applies
                        </h6>
                      </div>
                    </div>
                    <div className="col-6 col-lg-4 d-flex justify-content-center py-3 py-md-4">
                      <span>
                        {data.PatientData.screeningresponses.question28}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <div className="pricing-table-section text-center text-lg-left">
                <div className="row no-gutters">
                  <div className="col">
                    <h5 className="mb-4">Treatment History</h5>
                  </div>
                </div>
                <div className="border rounded">
                  <div className="row no-gutters align-items-center">
                    <div className="col-12 col-lg-6  py-3 py-md-4">
                      <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                        <h6 className="mb-0 ml-lg-4">
                          Have you been discharged, within the past 14 days,
                          from a Psychiatric Inpatient Hospital Admission?
                        </h6>
                      </div>
                    </div>
                    <div className="col-6 col-lg-4 d-flex justify-content-center py-3 py-md-4">
                      <span>
                        {data.PatientData.screeningresponses.question29}
                      </span>
                    </div>
                  </div>
                  <div className="row no-gutters align-items-center">
                    <div className="col-12 col-lg-6  py-3 py-md-4">
                      <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                        <h6 className="mb-0 ml-lg-4">
                          Have you recently been seen or screened within the
                          past 30 days, at a Mental Health Crisis Center?
                        </h6>
                      </div>
                    </div>
                    <div className="col-6 col-lg-4 d-flex justify-content-center py-3 py-md-4">
                      <span>
                        {data.PatientData.screeningresponses.question30}
                      </span>{" "}
                    </div>
                  </div>
                  <div className="row no-gutters align-items-center">
                    <div className="col-12 col-lg-6  py-3 py-md-4">
                      <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                        <h6 className="mb-0 ml-lg-4">
                          Are you currently being seen by a Psychiatrist or
                          Psychiatric Nurse Practitioner?
                        </h6>
                      </div>
                    </div>
                    <div className="col-6 col-lg-4 d-flex justify-content-center py-3 py-md-4">
                      <span>
                        {data.PatientData.screeningresponses.question31}
                      </span>{" "}
                    </div>
                  </div>
                  <div className="row no-gutters align-items-center">
                    <div className="col-12 col-lg-6  py-3 py-md-4">
                      <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                        <h6 className="mb-0 ml-lg-4">
                          Have you ever been seen by a Psychiatrist or
                          Psychiatric Nurse Practitioner
                        </h6>
                      </div>
                    </div>
                    <div className="col-6 col-lg-6 d-flex justify-content-center py-3 py-md-4">
                      <span>
                        {data.PatientData.screeningresponses.question32}
                      </span>
                    </div>
                  </div>
                  <div className="row no-gutters align-items-center">
                    <div className="col-12 col-lg-6  py-3 py-md-4">
                      <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                        <h6 className="mb-0 ml-lg-4">
                          Have you or are you currently being treated for any of
                          the following physical health problems? Select all
                          that applies
                        </h6>
                      </div>
                    </div>
                    <div className="col-6 col-lg-4 d-flex justify-content-center py-3 py-md-4">
                      <span>
                        {data.PatientData.screeningresponses.question28}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <div className="pricing-table-section text-center text-lg-left">
                <div className="row no-gutters">
                  <div className="col">
                    <h5 className="mb-4">COVID-19 & Tele-Health</h5>
                  </div>
                </div>
                <div className="border rounded">
                  <div className="row no-gutters align-items-center">
                    <div className="col-12 col-lg-6  py-3 py-md-4">
                      <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                        <h6 className="mb-0 ml-lg-4">
                          Have you been fully vaccinated against COVID-19?
                        </h6>
                      </div>
                    </div>
                    <div className="col-6 col-lg-4 d-flex justify-content-center py-3 py-md-4">
                      <span>
                        {data.PatientData.screeningresponses.covidvaccination}
                      </span>
                    </div>
                  </div>
                  <div className="row no-gutters align-items-center">
                    <div className="col-12 col-lg-6  py-3 py-md-4">
                      <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                        <h6 className="mb-0 ml-lg-4">
                          Do you have smart phone or computer/laptop with Have
                          you had and/or recovered from COVID-19 infection?
                        </h6>
                      </div>
                    </div>
                    <div className="col-6 col-lg-4 d-flex justify-content-center py-3 py-md-4">
                      <span>
                        {data.PatientData.screeningresponses.covidinfection}
                      </span>
                    </div>
                  </div>
                  <div className="row no-gutters align-items-center">
                    <div className="col-12 col-lg-6  py-3 py-md-4">
                      <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                        <h6 className="mb-0 ml-lg-4">
                          Do you have smart phone or computer/laptop with
                          webcam?
                        </h6>
                      </div>
                    </div>
                    <div className="col-6 col-lg-4 d-flex justify-content-center py-3 py-md-4">
                      <span>
                        {data.PatientData.screeningresponses.smartdevice}
                      </span>
                    </div>
                  </div>
                  <div className="row no-gutters align-items-center">
                    <div className="col-12 col-lg-6  py-3 py-md-4">
                      <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                        <h6 className="mb-0 ml-lg-4">
                          Do you have a good internet connection at where you
                          will be for your appointments?
                        </h6>
                      </div>
                    </div>
                    <div className="col-6 col-lg-4 d-flex justify-content-center py-3 py-md-4">
                      <span>
                        {data.PatientData.screeningresponses.internetconnection}
                      </span>{" "}
                    </div>
                  </div>
                  <div className="row no-gutters align-items-center">
                    <div className="col-12 col-lg-6  py-3 py-md-4">
                      <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                        <h6 className="mb-0 ml-lg-4">
                          Are you confident in using your Smartphone or
                          computer/laptop to engage in a doctor’s appointment?
                        </h6>
                      </div>
                    </div>
                    <div className="col-6 col-lg-4 d-flex justify-content-center py-3 py-md-4">
                      <span>
                        {data.PatientData.screeningresponses.smartdeviceuseage}
                      </span>{" "}
                    </div>
                  </div>
                  <div className="row no-gutters align-items-center">
                    <div className="col-12 col-lg-6  py-3 py-md-4">
                      <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                        <h6 className="mb-0 ml-lg-4">
                          Do you agree to engage in Telehealth Visits?
                        </h6>
                      </div>
                    </div>
                    <div className="col-6 col-lg-6 d-flex justify-content-center py-3 py-md-4">
                      <span>
                        {data.PatientData.screeningresponses.telehealthvisits}
                      </span>
                    </div>
                  </div>
                  <div className="row no-gutters align-items-center">
                    <div className="col-12 col-lg-6  py-3 py-md-4">
                      <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                        <h6 className="mb-0 ml-lg-4">
                          Are you interested in the using technology such as
                          apps on a smart phone and or internet based portals to
                          proactively manage your symptoms, follow your progress
                          towards your treatment goals?
                        </h6>
                      </div>
                    </div>
                    <div className="col-6 col-lg-4 d-flex justify-content-center py-3 py-md-4">
                      <span>
                        {data.PatientData.screeningresponses.useoftech}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {data && data.PatientRejected !== false ? (
              <div className="row justify-content-end sticky-top bg-white">
                <div className="col-lg-3 col-4 py-3 py-md-4 border-bottom">
                  <div className="d-flex flex-column align-items-center text-center">
                    <Link
                      onClick={(e) => {
                        handleAcceptPatient(e, data);
                      }}
                      className="btn btn-outline-primary btn-block"
                    >
                      <span>Accept Patient</span>
                    </Link>
                  </div>
                </div>
                <div className="col-lg-3 col-4 py-3 py-md-4 border-bottom">
                  <div className="d-flex flex-column align-items-center text-center">
                    <Link
                      onClick={(e) => {
                        handleRejectPatient(e, data);
                      }}
                      className="btn btn-primary btn-block"
                    >
                      <span>Reject Patient</span>
                    </Link>
                  </div>
                </div>
              </div>
            ) : (
              <div className="row justify-content-end sticky-top bg-white">
                <div className="col-lg-3 col-4 py-3 py-md-4 border-bottom">
                  <div className="d-flex flex-column align-items-center text-center">
                    <Link className="btn btn-outline-primary btn-block">
                      <span>Patient Accepted</span>
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
      </Fragment>
    );
  } else {
    return null;
  }
};
export default Patient;
