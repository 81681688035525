/**
 * COPYRIGHT OWNER:  EWAEN OKAO, MD.
 * CONTACT: EWAEN OKAO, MD. | ewaen.okao@healthisys.com | +18622151696.
 * FILE: API SETTINGS
 * DEVELOPED BY: Victor Olaitan, Reglobe Innovation Worx. ( victor@thevo.dev | +2349020686228 )
 * LAST UPDATED: March 29, 2022
 */
import axios from "axios";
const REACT_APP_URL = "https://bmbcpc-api.herokuapp.com/api/v1/";
// const REACT_APP_URL = "http://localhost:8009/api/v1/";

export const httpURL = axios.create({
  baseURL: REACT_APP_URL,
});

export const privateHttpURL = axios.create({
  baseURL: REACT_APP_URL,
});

privateHttpURL.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem(
    "accessToken"
  )}`;
  return config;
});
