/**
 * COPYRIGHT OWNER:  EWAEN OKAO, MD.
 * CONTACT: EWAEN OKAO, MD. | ewaen.okao@healthisys.com|+18622151696.
 * FILE: QUESTIONS & ANSWERS DATA
 * DEVELOPER: Victor Olaitan, Reglobe Innovation Worx. | victor@thevo.dev|+2349020686228
 * LAST UPDATED: March 29, 2022
 */

export const options = [
  { value: "yes", name: "Yes" },
  { value: "no", name: "No" },
];

export const optionsHave = [
  { value: "yes", name: "Yes" },
  { value: "no", name: "No" },
];

export const referrer = [
  { name: "Self-Referred", value: "self-referred" },
  { name: "Insurance", value: "Insurance" },
  { name: "Urgent Care", value: "Urgent Care" },
  { name: "Hospital, Crises", value: "Hospital, Crises" },
  { name: "Primary Care Provider", value: "Primary Care Provider" },
  { name: "Other", value: "Other" },
];

export const payer = [
  { name: "Self-Pay", value: "Self-Pay" },
  { name: "Private Insurance", value: "PrivateInsurance" },
  { name: "Union Insurance", value: "UnionInsurance" },
  { name: "MedicAid", value: "MedicAid" },
  { name: "MedicCare", value: "MedicCare" },
];

export const GeneralQuestions = [
  {
    title: "Are you currently experiencing thoughts of harming yourself?",
    message: "",
    id: "question1",
  },
  {
    title: "Any past suicidal attempts?",
    id: "question2",
  },
  {
    title: "Are you currently experiencing thoughts of harming others?",
    id: "question3",
  },
  {
    title:
      "Any past assaultive behaviors towards others or history of harming others?",
    id: "question4",
  },
  {
    title: "Are you currently hearing voices that no one else hears?",
    id: "question5",
  },
  {
    title: "Are the voices giving you commands?",
    id: "question6",
  },
  {
    title:
      "Are the commands that you should harm yourself or harm others or are you currently experiencing thoughts to harm others?",
    id: "question7",
  },
  {
    title: "Are you currently seeing things that no one else sees?",
    id: "question8",
  },
  {
    title: "Are the things only you are seeing directing you to do things",
    id: "question9",
  },
  {
    title: "Are these directions that you should harm yourself or harm others?",
    id: "question10",
  },
  {
    title:
      "Are your currently feeling that people are after you and watching you?",
    id: "question11",
  },
  {
    title:
      "Are these feeling making you feel that you should harm yourself or harm others?",
    id: "question12",
  },
  {
    title: "Are you currently feeling unsafe and threatened?",
    id: "question13",
  },
  {
    title:
      "Are you currently using heroin or misusing prescription pain medication such as Roxicodone, Tramadol, Hydrocodone (Vicodin®), Oxycodone (OxyContin®, Percocet®), Oxymorphone (Opana®), Morphine (Kadian®, Avinza®), Hydromorphone, (Dilaudid®), Codeine, Fentanyl, Tapentadol, Methadone, Buprenorphine (Suboxone or Subutex)?",
    id: "question14",
  },
  {
    title: "Are you currently using cocaine or methamphetamine or ecstasy etc?",
    id: "question15",
  },
  {
    title: "Are currently misusing any prescription medications? ",
    id: "question16",
  },
  {
    message:
      "One 'standard' drink (or one alcoholic drink equivalent) contains roughly 14 grams of pure alcohol, which is found in: About 350mls of regular beer, or can of beer which is usually about 5% alcohol, About 150mls of table wine, or half of a wine glass which is typically about 12% alcohol, About 45mls of distilled spirits such as gin, whiskey, brandy, vodka, rum which is about 40% alcohol",
    title:
      "Are you currently misusing alcohol? If female, 4 or more drinks/day or if male, more than 5 or more drinks/day ",
    id: "question17",
  },
  {
    title:
      "Do you currently smoke marihuana/cannabis or cannabis derivatives such cannabinoid in any form, smoking, in form of gummies or CBD oil or pills etc?",
    id: "question18",
  },
  {
    title:
      "Have you ever been treated at detox unit or inpatient or residential drug treatment program?",
    id: "question19",
  },
  {
    title:
      "Are you currently or within the last 6 months been prescribed any of the following medications such as Valium (diazepam), Xanax (alprazolam), Ativan (lorazepam), Serax (oxazepam), Klonopin (clonazepam), Librium (Chlordiazepoxide), Restoril (Temazepam)",
    id: "question20",
  },
  {
    title:
      "Are you currently on any prescription opioid pain medications such as Hydrocodone (Vicodin®), Oxycodone (OxyContin®, Percocet®), Oxymorphone (Opana®), Morphine (Kadian®, Avinza®), Hydromorphone, (Dilaudid®), Codeine, Fentanyl, Tapentadol, Methadone, Buprenorphine (Suboxone or Subutex) ?",
    id: "question21",
  },
  {
    title: "Are you currently smoking cigarettes or cigars or vaping nicotine?",
    id: "question22",
  },
  {
    title:
      "Are you interested in quitting use of tobacco products or nicotine? ",
    id: "question23",
  },
  {
    title:
      "Are you currently, experiencing any of the following acute physical health symptoms such as sudden acute chest pain or tightness, neck, back, or arms, abnormal or very fast heart beats",
    id: "question24",
  },
  {
    title:
      "Are you currently, experiencing any of the following acute physical health symptoms such numbness/weakness on one side of your face or arms or legs, sudden change in your voice or unable to speak, sudden change in your facial appearance or facial drooping, or lightheadedness or dizziness, feeling confused or disorientated or severe headache?",
    id: "question25",
  },
  {
    title:
      "Are you currently, having trouble breathing, and feel you are suffocating?",
    id: "question26",
  },
  {
    title:
      "Are you currently, having a fever or vomiting or experiencing acute abdominal pain? ",
    id: "question27",
  },
  {
    title:
      "Have you have been diagnosed with Diabetes, High Cholesterol, Seizures, High Blood Pressure (Hypertension), Arrhythmia (Irregular heartbeat), liver disease (hepatitis), Any type of Cancer, kidney disease, thyroid disease, heart disease, history of loss of consciousness or being in a coma or auto immune disease or head injury or any other medical issues?",
    id: "question28",
  },
  {
    title:
      "Have you been discharged, within the past 14 days, from a Psychiatric Inpatient Hospital Admission?",
    id: "question29",
  },
  {
    title:
      "Have you recently been seen or screened within the past 30 days, at a Mental Health Crisis Center?",
    id: "question30",
  },
  {
    title:
      "Are you currently being seen by a Psychiatrist or Psychiatric Nurse Practitioner?  ",
    id: "question31",
  },
  {
    title:
      "Have you ever been seen by a Psychiatrist or Psychiatric Nurse Practitioner",
    id: "question32",
  },
  {
    title: "Are you currently taking any Psychiatric Medications? ",
    id: "question33",
  },
];

export const MentalTreatment = [
  {
    label: "Individual or Group counseling/therapy",
    value: "Individual or Group counseling/therapy",
  },
  { label: "Inpatient treatment", value: "Inpatient treatment" },
  {
    label: "Partial Hospitalization program ",
    value: "Partial Hospitalization program ",
  },
  { label: "Intensive Outpatient ", value: "Intensive Outpatient " },
  { label: "Dual diagnoses treatment", value: "Dual diagnoses treatment" },
  { label: "Outpatient treatment", value: "Outpatient treatment" },
  { label: "IOP", value: "IOP" },
  { label: "MICA", value: "MICA" },
  { label: "None", value: "None" },
];

export const genderatbirth = [
  { name: "Male", value: "male" },
  { name: "Female", value: "female" },
];

export const currentgender = [
  { name: "Male", value: "male" },
  { name: "Female", value: "female" },
  { name: "Transgender", value: "trans" },
  { name: "None of the above", value: "none" },
];

export const TreatedAilments = [
  { label: "Head injury ", value: "Head injury" },
  { label: "Loss of consciousness/Coma", value: "Loss of consciousness/Coma" },
  { label: "Seizures", value: "Seizures" },
  { label: "Thyroid disease ", value: "Thyroid disease " },
  { label: "Diabetes", value: "Diabetes" },
  { label: "Any Heart disease ", value: "Any Heart disease " },
  { label: "High Cholesterol", value: "High Cholesterol" },
  { label: "High Blood pressure ", value: "High Blood pressure " },
  {
    label: "Any Lung Disease e.g. Asthma or COPD	",
    value: "Any Lung Disease e.g. Asthma or COPD	",
  },
  { label: "Liver disease", value: "Liver disease" },
  { label: "Kidney disease", value: "Kidney disease" },
  {
    label: "Any Intestinal disease e.g. Crohn or UC",
    value: "Any Intestinal disease e.g. Crohn or UC",
  },
  {
    label: "Auto immune disease e.g. Rheumatoid Arthritis/lupus",
    value: "Auto immune disease e.g. Rheumatoid Arthritis/lupus",
  },
  { label: "Any Cancer", value: "Any Cancer" },
  { label: "None", value: "None" },
];
