import React, { Fragment } from "react";

const CareProvided = () => {
  return (
    <Fragment>
      <div className="bg-primary-3 o-hidden" data-overlay>
        <section className="text-white pb-0">
          <div className="container pb-5">
            <div className="row justify-content-center text-center">
              <div className="col-md-9 col-lg-8 col-xl-9">
                <h1 className="display-3">Higher level of Care Required</h1>
                <p className="lead mb-5">
                  Your current symptom indicate that you need an
                  <a
                    href="https://bmbpc.netlify.app/level-of-care"
                    target={"_blank"}
                    rel={"noreferrer"}
                  >
                    {" "}
                    level of care{" "}
                  </a>
                  that is not currently available currently at BMB Consultants.
                </p>
              </div>
            </div>
          </div>
          {/*<div
              className="position-absolute w-50 h-100 top left"
              data-jarallax-element="100 50"
            >
              <div className="blob blob-4 bg-gradient w-100 h-100 top left"></div>
            </div>
             <div className="divider divider-bottom bg-white"></div> */}
        </section>
      </div>
      <section>
        <div className="container">
          <div className="row align-items-center justify-content-around text-center text-lg-left">
            <div
              className="col-md-9 col-lg-9 col-xl-9 mb-4 mb-md-5 mb-lg-0 order-lg-2"
              data-aos="fade-in"
            >
              <h6 className="h3">Please</h6>
              <ul className="lead">
                <li className="pb-3">
                  <strong>Call Local 911 </strong>or go to the nearest Emergency
                  Room
                </li>{" "}
                <li>
                  If possible, Contact your emergency contact or next of kin
                  immediately for help while continuing to call the authorities
                </li>
                <li className="pb-3">
                  Use <strong>“Signal for Help,” or “#SignalForHelp,”</strong>{" "}
                  is A one-handed gesture that women and children (and men as
                  well) can use on a video call or in person to communicate that
                  they feel threatened. The movement involves facing your palm
                  to the camera or person, tucking your thumb into your palm,
                  and folding your four fingers over the top of your thumb.
                </li>{" "}
              </ul>{" "}
            </div>
            {/* <div
                className="col-md-9 col-lg-6 col-xl-4 order-lg-1"
                data-aos="fade-in"
              >
                <img
                  src="assets/img/about-2.jpg"
                  alt="About"
                  className="img-fluid rounded shadow"
                />
              </div> */}
          </div>
        </div>
      </section>
    </Fragment>
  );
};
export default CareProvided;
